
import Log from './utils/Log'

window.Logger = new Log()
window.Logger.logToConsole = false
window.Logger.logToServer = false

if (
    window.location.href.indexOf("127.0.0.1") >= 0) {
    window.Logger.logToConsole = true
}

if (window.location.href.indexOf("192.168.0") >= 0) {
    window.Logger.logToServer = true
}

if (window.location.href.indexOf("logger") >= 0) {
    window.Logger.logToServer = true
}

import { createApp } from 'vue/dist/vue.esm-bundler';
import { store } from './store';
import App from './App.vue';
import router from './router';
import Toaster from '@meforma/vue-toaster';
import TWEEN from "@tweenjs/tween.js";
import VueCarousel from '@chenfengyuan/vue-carousel';
import SoundManager from "@/utils/SoundManager";

window.onerror = function (msg, url, line, col, error) {
    window.Logger.log({
        msg: msg,
        url: url,
        line: line,
        col: col,
        error: error,
    })
    return true;
};

function animate(time) {
    requestAnimationFrame(animate);
    TWEEN.update(time);
}
requestAnimationFrame(animate);

const app = createApp(App);

app.component(VueCarousel.name, VueCarousel);

app.use(store);
app.use(router);
app.use(Toaster, {
    position: 'top-right',
    duration: 2500
});
app.mount('#app');

app.config.globalProperties.$soundManager = new SoundManager()
app.config.globalProperties.$soundManager.init()