<template>
  <div v-if="isGame">
    <div class="row text-left mt-3 balance text-start" @click="openDrawer">
      <div class="col-7">
        <span style="color: #3dad3f; font-size: 20px; padding-right: 5px"
          >#</span
        >
        <span style="font-size: 20px; padding-right: 5px">{{
          leaderboardPlace
        }}</span>
        <span style="position: relative; font-size: 12px; top: -2px"
          >IN LEADERBOARD</span
        >
      </div>
      <div class="col-5 text-end">
        <span style="position: relative; font-size: 12px; top: -2px">{{
          leaderboardDate
        }}</span>
      </div>
    </div>
    
    <div class="balance mt-3 mb-3">
    
      <div class="balance-current">
        {{ balance == 0 ? "0.0" : balance }}
        {{ $store.getters.getCurrency }}
      </div>
      
      <div class="balance-scg" v-if="$store.getters.getCurrency=='TT'">{{ tokenbalance }} SCG</div>

      <div class="balance-controls controlsWrapper">
        <div class="controls">
          <i
            @click="$store.commit('setSoundOn', !$store.getters.getSoundOn)"
            :class="[
              $store.getters.getSoundOn ? 'tio-volume-up' : 'tio-volume-off',
            ]"
          ></i>
          <i
            v-if="$store.getters.getEnableFast"
            @click="$store.commit('setFastOn', !$store.getters.getFastOn)"
            :class="[$store.getters.getFastOn ? 'tio-flash' : 'tio-flash-off']"
          ></i>
        </div>
      </div>

      <div class="clear"></div>
    </div>
    
    <div
      class="container balance pt-1 mb-3 scroll"
      v-if="hasTokens"
      :class="[$store.getters.getCurrencyEnabled ? '' : 'disabled']"
    >
      <router-link
        :to="
          this.$route.meta.uri +
          '/' +
          $store.getters.getConfig.name +
          '/' +
          token.name
        "
        v-for="token in tokens"
        :key="token"
      >
        <img
          :src="
            token.selected
              ? '/images/tokens/' + token.name + '-selected.svg'
              : '/images/tokens/' + token.name + '.svg'
          "
          @click="changeCurrency(token.name)"
        />
      </router-link>
    </div>
  </div>
</template>
      
<script>
import { computed } from "vue";
export default {
  props: ["leaderboardPlace", "leaderboardDate"],
  data() {
    return {
      balance: computed(() => this.$store.state.balance),
      tokenbalance: computed(() => this.$store.state.tokenbalance),
      
      isGame: this.$route.meta.isGame,
      soundControl: this.$store.getters.getSoundOn,
      speedControl: this.$store.getters.getFastOn,
      tokens: this.$store.getters.getTokensList,
      hasTokens: this.$store.getters.getTokensList,
    };
  },
  watch: {
    $route() {
      this.isGame = this.$route.meta.isGame;
      this.hasTokens = this.$store.getters.getTokensList;
      this.tokens = this.$store.getters.getTokensList;
    },
  },
  methods: {
    openDrawer() {
      var drawerButton = document.getElementById('menu');
      drawerButton.click()
      var leaderboardButton = document.getElementById('drawer_link_leaderboard');
      leaderboardButton.click()
    },
    changeCurrency(currency) {
      for (const key in this.tokens) {
        if (Object.hasOwnProperty.call(this.tokens, key)) {
          const element = this.tokens[key];
          element.selected = false;
        }
      }
      this.tokens[currency].selected = true;
      this.$store.commit("setUserCurrency", this.tokens[currency]);
    },
  },
};
</script>
<style scoped>
.controlsWrapper {
  position: relative;
  right: 1rem;
}
.controls {
  position: absolute;
  right: 0px;
  margin-top: -35px;
  margin-right: -20px;
}

.disabled {
  pointer-events: none;
  opacity: 0.5;
}

.controls i {
  margin-right: 10px;
  font-size: 2rem;
}
.balance {
  margin: 0 auto;
  width: 100%;
  padding: 0px 0rem;
  max-width: 800px;
  display:flex;
  justify-content:space-between;
}

.balance-current {
  height: 40px;
  line-height: 40px;
  background: #fff;
  border-radius: 5px;
  padding: 0px 0.5rem;
  flex:1;
  margin-right:0.5rem;
}

.balance-scg {
  height: 40px;
  line-height: 40px;
  background: #fff;
  border-radius: 5px;
  padding: 0px 0.5rem;
  flex:1;
  margin-right:0.5rem;
}


.balance-controls {
  height: 40px;
  line-height: 40px;
  max-width: 800px;
  background: #fff;
  border-radius: 5px;
  padding: 0px 0rem;
  width: 100px;
}
.balance .balance-controls{
  width:auto;
  margin-left:auto;
  border-radius: 5px;
}
.balance .balance-controls .controls{
  position:relative;
  right: 0px;
  margin-top: 0;
  margin-right: 0;

}
.balance .balance-controls .controls i{
    margin-right: 0;

}
.balance .controlsWrapper{
  right:0;
}
.tokenselected {
  border: 1px solid #ff0000;
}

.scroll {
  width: 100%;
  height: 50px;
  overflow-x: scroll;
  overflow-y: hidden;
  margin: 0 auto;
  display: grid;
  grid-auto-flow: column;
  grid-template-columns: auto;
  -ms-overflow-style: none;
  scrollbar-width: none;
}
.scroll::-webkit-scrollbar {
  display: none;
}
.scroll img {
  margin-right: 10px;
  height: 35px;
}
</style>    