<template>
  <div class="container-fluid bg-white">
    <div class="headerBar" :class="drawerVisible ? 'noscroll' : ''">
      <div class="container">
        <div class="row mr-0">
          <div :class="this.$route.meta.isGame ? 'col-2' : 'col-3'" class="col-2 ps-0" style="text-align: left">
            <router-link to="/">
              <img
                :src="
                  '/logo/' +
                  $store.getters.getConfig.name +
                  '/' +
                  $route.meta.logo +
                  '.svg'
                "
                id="logo"
              />
            </router-link>
          </div>
          <div :class="this.$route.meta.isGame ? 'col-8' : 'col-7'" class="pr-0 mr-0">
            <vue-carousel
              :data="data"
              :indicatorType="indicatorType"
              :controls="controls"
              :interval="interval"
              ref="slider"
            ></vue-carousel>
          </div>
          <div class="col-2 p-0">
            <div id="menu" class="float-end toggle" @click="menu(true)">
              <img :src="burger" id="burger" />
            </div>
          </div>
        </div>
      </div>
      <div class="right-drawer" :class="drawerVisible ? 'drawerOpen' : ''">
        <div id="menuInner">
          <div style="text-align: right">
            <img src="@/assets/img/close.svg" id="close" @click="menu(false)" />
          </div>
          <div>
            <div id="menuLine"></div>
            <Menu />
            <img src="@/assets/img/decor-1.svg" id="decor" />
          </div>
        </div>
      </div>

      <div
        class="drawer-mask"
        :style="{
          width: drawerVisible ? '100vw' : '0',
          opacity: drawerVisible ? '0.6' : '0',
        }"
        @click="menu(false)"
      ></div>
    </div>
  </div>
</template>

<script>
import Menu from "@/components/Menu.vue";

export default {
  components: {
    Menu,
  },
  data() {
    window.Logger.log(this.$store.getters.getConfig.view.burger)
    return {
      items: [
        {
          label: "New",
          icon: "pi pi-fw pi-plus",
        },
      ],
      drawerVisible: false,
      pageName: this.currentRouteName,
      logoLink: this.$route.meta.isGame ? "#" : "/",
      data: this.getAds(),
      indicatorType: "disc",
      burger: this.$store.getters.getConfig.view.burger,
      controls: false,
      interval: parseInt(this.$store.getters.getSliderInterval),
    };
  },
  methods: {
    menu(state) {
      if (state) {
        this.drawerVisible = true;
        document.body.style.overflow = "hidden";
      } else {
        this.drawerVisible = false;
        document.body.style.removeProperty("overflow");
      }
    },
    getAds() {
      var routes = this.$router.getRoutes();
      var miniAds = new Array();
      miniAds.push('<div class="slide"><router-link to="/scg-staking"><img src="/logo/mini/v2/scg.svg"/></router-link></div>')
      var games = this.$store.getters.getConfig.games;

      for (var i = 0; i < routes.length; i++) {
        if (routes[i].meta.isGame) {
          const currentGame = routes[i].meta.uri;

          if(!games[currentGame.replace('/','')].active || currentGame == this.$route.meta.uri) {
            continue;
          }

          miniAds.push(
            '<div class="slide"><router-link to="' +
              currentGame +
              '"><img src="/logo/mini/v2/' +
              currentGame.replace('/','') +
              '.svg"/></router-link></div>'
          );
        }
      }

      return miniAds;
    },
  },
  watch: {
    drawerVisible: function (s) {
      if (!s) {
        document.body.style.removeProperty("overflow");
      }
    },
    $route: function () {
      this.$refs.slider.play();
      this.data = this.getAds();
    },
  },
};
</script>
<style>
.soonIcon {
  font-size: 8px;
  font-family: arial;
  position: relative;
  top: -10px;
  left: 44px;
}
.soon {
  position: relative;
  top: -7px;
}
sup {
  font-weight: 400 !important;
  font-family: arial;
  font-size: 8px;
}
#infoicon {
  margin-top: -3px;
  margin-right: 5px;
}
#infoHolder {
  padding: 5px 15px;
  padding-left: 0px;
  height: 60px;
  line-height: 50px;
  float: left;
  color: #fff;
  font-size: 1.2em;
}
#pageHeader {
  width: 100%;
  height: 60px;
  background: #262626;
  position: fixed;
}
#closeHolder {
  background: var(--chain-color);
  width: 60px;
  padding: 5px 15px;
  height: 60px;
  line-height: 50px;
  float: right;
  position: absolute;
  right: 0;
  cursor: pointer;
}
#decor {
  position: absolute;
  bottom: 3%;
  right: 3%;
}
#menuInner {
  padding: 0px 5%;
}
#menuLine {
  width: 100%;
  border-bottom: 1px solid #d4d4d4;
}
#burger {
  margin-top: -12px;
}
#close {
  margin-right: 10px;
  margin-top: -2px;
  cursor: pointer;
}
.drawerMenu {
  margin: 0px;
  padding: 0px;
  text-align: left;
  list-style-type: none;
  padding-top: 10px;
}
.drawerMenu li {
  padding-top: 10px;
  line-height: 20px;
  font-size: 1em;
}
.drawerMenu li a {
  color: #262626;
}

.drawerMenuIcon {
  width: 1em;
  height: 1em;
  border-radius: 1em;
  background: var(--chain-color);
  padding: 8px;
  margin-right: 8px;
  color: var(--menuicon-color);
}

.drawerVisible {
  overflow-y: hidden !important;
}


.headerBar {
  max-width: 800px;
  width: 100%;
  background: #fff;
  height: 80px;
  padding: 0px 5px;
  line-height: 80px;
  padding-right: 0px;
  margin: 0 auto;
}
.headerBar #logo {
  height: 60px;
}
.ttlogo {
  position: relative;
  top: -2px;
  margin-right: 5px;
}
#menu {
  background-color: var(--chain-color);
  width: 100px;
  height: 80px;
  position: relative;
}
.toggle {
  position: fixed;
  width: 120px;
  background: white;
  border: 0;
  padding: 5px;
  cursor: pointer;
}
.right-drawer {
  position: absolute;
  top: 0;
  right: 0;
  width: 0; /* initially */
  overflow: hidden;
  height: 100vh;
  background: #fff;
  z-index: 200;
  transition: all 0.2s;
  position: fixed;
  text-align: right;
}
.drawerOpen {
  width: 25vw;
}
.drawer-mask {
  position: absolute;
  left: 0;
  top: 0;
  width: 0; /* initially */
  height: 100vh;
  background: #000;
  opacity: 0.3;
  z-index: 199;
  transition: opacity 0.2s;
  position: fixed;
  overflow: hidden;
}
.pageDrawer {
  position: absolute;
  top: 0;
  right: 0;
  width: 0; /* initially */
  overflow: scroll;
  height: 100vh;
  background: #fff;
  z-index: 201;
  transition: all 0.2s;
  position: fixed;
  text-align: right;
}
.pageOpen {
  width: 100vw;
}
.vue-carousel {
  max-width: 80% !important;
  margin: 0 auto;
}
.slide {
  align-items: center;
  display: flex;
  justify-content: center;
}
.slide img {
  height: 50px;
  margin-top: -20px;
}
.vue-carousel__indicators {
  bottom: -7px !important;
}
.vue-carousel__indicators--disc > .vue-carousel__indicator:before {
  background: #000;
  color: #fff;
}
@media (max-width: 992px) {
  .headerBar {
    height: 50px;
    line-height: 50px;
  }
  .headerBar #logo {
    height: 40px;
  }
  #menu {
    height: 50px;
    width: 65px;
  }
  .drawerOpen {
    width: 75vw;
  }
  .slide img {
    height: 30px;
    margin-top: -10px;
  }
}
@media (max-width: 575px) {
  #menu {
    right: -13px;
  }
}
</style>