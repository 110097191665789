<template>
  <div v-if="display == 'payoutTableLucky777'">
    <ul class="aboutList">
      <li>
        <span style="font-size: 1.5em"
          >Bellow you can find the game coefficients</span
        >
      </li>
      <li>
        <div class="payoutItem payoutItem1">
          <div class="payoutItem_inside payoutItem1_inside">
            <img class="icon" src="/games/lucky777/item_7.png" />
            <img class="icon" src="/games/lucky777/item_7.png" />
            <img class="icon" src="/games/lucky777/item_7.png" />
          </div>
          <div class="payoutItem1_inside2">200</div>
        </div>
      </li>
      <li>
        <div class="payoutItem payoutItem2">
          <div class="payoutItem_inside payoutItem2_inside">
            <img class="icon" src="/games/lucky777/item_b.png" />
            <img class="icon" src="/games/lucky777/item_b.png" />
            <img class="icon" src="/games/lucky777/item_b.png" />
          </div>
          <div class="payoutItem1_inside2">150</div>
        </div>
      </li>
      <li>
        <div class="payoutItem payoutItem1">
          <div class="payoutItem_inside payoutItem1_inside">
            <img class="icon" src="/games/lucky777/item_w.png" />
            <img class="icon" src="/games/lucky777/item_w.png" />
            <img class="icon" src="/games/lucky777/item_w.png" />
          </div>
          <div class="payoutItem1_inside2">100</div>
        </div>
      </li>
      <li>
        <div class="payoutItem payoutItem2">
          <div class="payoutItem_inside payoutItem2_inside">
            <img class="icon" src="/games/lucky777/item_w.png" />
            <img class="icon" src="/games/lucky777/item_w.png" />
            <img class="icon" src="/games/lucky777/item_b.png" />
          </div>
          <div class="payoutItem1_inside2">100</div>
        </div>
      </li>

      <li>
        <div class="payoutItem payoutItem1">
          <div class="payoutItem_inside payoutItem1_inside">
            <img class="icon" src="/games/lucky777/item_z.png" />
            <img class="icon" src="/games/lucky777/item_z.png" />
            <img class="icon" src="/games/lucky777/item_z.png" />
          </div>
          <div class="payoutItem1_inside2">18</div>
        </div>
      </li>
      <li>
        <div class="payoutItem payoutItem2">
          <div class="payoutItem_inside payoutItem2_inside">
            <img class="icon" src="/games/lucky777/item_z.png" />
            <img class="icon" src="/games/lucky777/item_z.png" />
            <img class="icon" src="/games/lucky777/item_b.png" />
          </div>
          <div class="payoutItem1_inside2">18</div>
        </div>
      </li>

      <li>
        <div class="payoutItem payoutItem1">
          <div class="payoutItem_inside payoutItem1_inside">
            <img class="icon" src="/games/lucky777/item_q.png" />
            <img class="icon" src="/games/lucky777/item_q.png" />
            <img class="icon" src="/games/lucky777/item_q.png" />
          </div>
          <div class="payoutItem1_inside2">14</div>
        </div>
      </li>
      <li>
        <div class="payoutItem payoutItem2">
          <div class="payoutItem_inside payoutItem2_inside">
            <img class="icon" src="/games/lucky777/item_q.png" />
            <img class="icon" src="/games/lucky777/item_q.png" />
            <img class="icon" src="/games/lucky777/item_b.png" />
          </div>
          <div class="payoutItem1_inside2">14</div>
        </div>
      </li>

      <li>
        <div class="payoutItem payoutItem1">
          <div class="payoutItem_inside payoutItem1_inside">
            <img class="icon" src="/games/lucky777/item_o.png" />
            <img class="icon" src="/games/lucky777/item_o.png" />
            <img class="icon" src="/games/lucky777/item_o.png" />
          </div>
          <div class="payoutItem1_inside2">10</div>
        </div>
      </li>
      <li>
        <div class="payoutItem payoutItem2">
          <div class="payoutItem_inside payoutItem2_inside">
            <img class="icon" src="/games/lucky777/item_o.png" />
            <img class="icon" src="/games/lucky777/item_o.png" />
            <img class="icon" src="/games/lucky777/item_b.png" />
          </div>
          <div class="payoutItem1_inside2">10</div>
        </div>
      </li>
      <li>
        <div class="payoutItem payoutItem1">
          <div class="payoutItem_inside payoutItem1_inside">
            <img class="icon" src="/games/lucky777/item_7.png" />
            <img class="icon" src="/games/lucky777/item_7.png" />
            <img class="icon" src="/games/lucky777/item_any.png" />
          </div>
          <div class="payoutItem1_inside2">5</div>
        </div>
      </li>
      <li>
        <div class="payoutItem payoutItem1">
          <div class="payoutItem_inside payoutItem1_inside">
            <img class="icon" src="/games/lucky777/item_b.png" />
            <img class="icon" src="/games/lucky777/item_b.png" />
            <img class="icon" src="/games/lucky777/item_any.png" />
          </div>
          <div class="payoutItem1_inside2">2</div>
        </div>
      </li>
      <li>
        <div class="payoutItem payoutItem1">
          <div class="payoutItem_inside payoutItem1_inside">
            <img class="icon" src="/games/lucky777/item_a.png" />
            <img class="icon" src="/games/lucky777/item_a.png" />
            <img class="icon" src="/games/lucky777/item_any.png" />
          </div>
          <div class="payoutItem1_inside2">2</div>
        </div>
      </li>
      <li>
        <div class="payoutItem payoutItem1">
          <div class="payoutItem_inside payoutItem1_inside">
            <img class="icon" src="/games/lucky777/item_l.png" />
            <img class="icon" src="/games/lucky777/item_l.png" />
            <img class="icon" src="/games/lucky777/item_any.png" />
          </div>
          <div class="payoutItem1_inside2">2</div>
        </div>
      </li>
      <li>
        <div class="payoutItem payoutItem2">
          <div class="payoutItem_inside payoutItem2_inside">
            <img class="icon" src="/games/lucky777/item_7.png" />
            <img class="icon" src="/games/lucky777/item_any.png" />
            <img class="icon" src="/games/lucky777/item_any.png" />
          </div>
          <div class="payoutItem1_inside2">2</div>
        </div>
      </li>
      <li>
        <div class="payoutItem payoutItem2">
          <div class="payoutItem_inside payoutItem2_inside">
            <img class="icon" src="/games/lucky777/item_b.png" />
            <img class="icon" src="/games/lucky777/item_any.png" />
            <img class="icon" src="/games/lucky777/item_any.png" />
          </div>
          <div class="payoutItem1_inside2">1</div>
        </div>
      </li>
      <li>
        <div class="payoutItem payoutItem2">
          <div class="payoutItem_inside payoutItem2_inside">
            <img class="icon" src="/games/lucky777/item_a.png" />
            <img class="icon" src="/games/lucky777/item_any.png" />
            <img class="icon" src="/games/lucky777/item_any.png" />
          </div>
          <div class="payoutItem1_inside2">1</div>
        </div>
      </li>
      <li>
        <div class="payoutItem payoutItem2">
          <div class="payoutItem_inside payoutItem2_inside">
            <img class="icon" src="/games/lucky777/item_l.png" />
            <img class="icon" src="/games/lucky777/item_any.png" />
            <img class="icon" src="/games/lucky777/item_any.png" />
          </div>
          <div class="payoutItem1_inside2">1</div>
        </div>
      </li>

      <li>And RTP is <span style="color: #92d299">97.89%</span></li>
    </ul>
  </div>
</template>
<script>
export default {
  props: ["display"],
  data() {
    var coeffsTable = [];

    return {
      coeffsTable,
    };
  },
};
</script>
<style>
.pageDrawer {
  background: #f0edf6 !important;
}
</style>

<style scoped>
.payoutItem2 {
  background: #ffffff;
  border: 4px solid #ffffff;
}

.payoutItem2_inside {
  background: #f0edf6;
  border: 4px solid #ffffff;
}

.payoutItem1 {
  background: #f0edf6;
  border: 4px solid #ffffff;
}

.payoutItem1_inside {
  background: #ffffff;
  border: 4px solid #ffffff;
}

.payoutItem {
  box-sizing: border-box;
  border-radius: 60px;
  width: 100%;
  height: 70px;
}

.payoutItem_inside {
  box-sizing: border-box;
  border-radius: 60px;
  width: 80%;
  height: 62px;
  float: left;
}

.payoutItem1_inside2 {
  float: left;
  width: 15%;
  height: 62px;
  line-height: 62px;
  text-align: center;
}

.icon {
  width: 45px;
  float: left;
  margin-left: 20px;
}

.aboutList {
  margin: 0px;
  padding: 0px;
  text-align: left;
  list-style-type: none;
  padding-top: 10px;
}
.aboutList li {
  padding: 5px;
  line-height: 25px;
}
</style>
