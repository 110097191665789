import thundercore from "./thundercore.json";
import polygon from "./polygon.json";
import csc from "./csc.json";

const chains = {
    thundercore: thundercore,
    polygon: polygon,
    csc: csc
};

export default chains;