import { createStore } from 'vuex'

let user = {
  "wallet": false,
  "notification": false,
  "game": false,
  "soundOn": true,
  "fastOn": false
}

if (!localStorage.getItem("user")) {
  localStorage.setItem("user", JSON.stringify(user));
}

if (!JSON.parse(localStorage.getItem("user")).soundOn) {
  var newUser = JSON.parse(localStorage.getItem("user"));
  newUser.soundOn = true;
  localStorage.setItem("user", JSON.stringify(newUser));
}

if (!JSON.parse(localStorage.getItem("user")).fastOn) {
  var fastUser = JSON.parse(localStorage.getItem("user"));
  fastUser.fastOn = false;
  localStorage.setItem("user", JSON.stringify(fastUser));
}

if (!JSON.parse(localStorage.getItem("user")).currency) {
  var currUser = JSON.parse(localStorage.getItem("user"));
  currUser.currency = {
    name: "tt",
    address: "0x0000000000000000000000000000000000000000",
    selected: true,
  };
  localStorage.setItem("user", JSON.stringify(currUser));
}

import chains from "./config/index.js";

const defaultChain = localStorage.getItem('chain') || 'thundercore';

export const store = createStore({
  state() {
    //window.Logger.log(chains)
    //window.Logger.log(defaultChain)
    return {
      sliderInterval: process.env.VUE_APP_MINI_SLIDER_INTERVAL,
      balance: 0,
      tokenbalance: 0,
      user: JSON.parse(localStorage.getItem("user")),
      fastEnabled: true,
      currencyEnabled: false,
      config: chains[defaultChain],
      currency: chains[defaultChain].currency_code,
      tokens: chains[defaultChain].tokens,
      css: chains[defaultChain].css
    }
  },
  mutations: {
    setFastOn(state, fastOn) {
      state.user.fastOn = fastOn
      localStorage.setItem("user", JSON.stringify(state.user));
    },
    setUserCurrency(state, currency) {
      state.user.currency = currency
      localStorage.setItem("user", JSON.stringify(state.user));
    },
    setEnableFast(state, fastOn) {
      state.fastEnabled = fastOn
    },
    setCurrencyEnabled(state, ce) {
      window.Logger.log("setCurrencyEnabled "+ce)
      state.currencyEnabled = ce
    },
    setSoundOn(state, snd) {
      state.user.soundOn = snd
      localStorage.setItem("user", JSON.stringify(state.user));
    },
    setTokenBalance(state, newBal) {
      state.tokenbalance = newBal
    },
    setBalance(state, newBal) {
      state.balance = newBal
      localStorage.setItem("user", JSON.stringify(state.user));
    },
    setUser(state, newUser) {
      state.user = newUser
      localStorage.setItem("user", JSON.stringify(state.user));
    },
    setCurrency(state, currency) {
      state.currency = currency
    },
    setUserWallet(state, newWallet) {
      state.user.wallet = newWallet
      localStorage.setItem("user", JSON.stringify(state.user));
    },
    setChain(state, newChain) {
      window.Logger.log("newChain " + JSON.stringify(newChain))
      localStorage.setItem('chain', newChain.name);
      state.config = chains[newChain.name]

      const colors = store.getters.getConfig.view.colors
      let style = document.documentElement.style;
      for (let key of Object.keys(colors)) {
        style.setProperty("--" + key, colors[key]);
      }

      store.commit('setCurrency', store.getters.getConfig.currency_code)
      store.commit('setTokens', store.getters.getConfig.tokens)
      store.commit('setCss', store.getters.getConfig.css)
      for (const key in chains[newChain.name].tokens) {
        if (Object.hasOwnProperty.call(chains[newChain.name].tokens, key)) {
          const element = chains[newChain.name].tokens[key];
          element.selected = false;
        }
      }
      
      if (chains[newChain.name].tokens[newChain.token] == undefined) {
        //newChain.token = chains[newChain.name].currency_code.toLowerCase()
        store.commit('setUserCurrency', chains[newChain.name].default_token)
      } else {
        chains[newChain.name].default_token.selected = false
        chains[newChain.name].tokens[newChain.token].selected = true
        store.commit('setUserCurrency', chains[newChain.name].tokens[newChain.token])
      }


    },
    setTokens(state, newTokens) {
      state.tokens = newTokens
    },
    setCss(state, newCss) {
      state.css = newCss
    }
  },
  getters: {
    getUserCurrency(state) {
      return state.user.currency
    },
    getSliderInterval(state) {
      return state.sliderInterval;
    },
    getEnableFast(state) {
      return state.fastEnabled
    },
    getCurrencyEnabled(state) {
      return state.currencyEnabled
    },
    getTokensList(state) {
      return state.config.tokens
    },
    getBalance(state) {
      return state.balance
    },
    getCurrency(state) {
      return state.currency
    },
    getUser(state) {
      return state.user
    },
    getUserWallet(state) {
      return state.user.wallet
    },
    getView(state) {
      return state.config.view
    },
    getGameLimits(state) {
      return state.config.games
    },
    getSoundOn(state) {
      return state.user.soundOn
    },
    getFastOn(state) {
      return state.user.fastOn
    },
    getConfig(state) {
      return state.config
    },
  }
})