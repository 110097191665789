<template>
  <div
    v-if="backdrop || state"
    class="PromoPopup-backdrop"
    :class="isMobile() ? '' : 'desktop'"
  >
    <div
      class="PromoPopup"
      :class="classState ? 'slideIn ' : 'slideOut'"
      role="dialog"
      aria-labelledby="PromoPopupTitle"
      aria-describedby="PromoPopupDescription"
    >
      <section class="PromoPopup-header" id="PromoPopupTitle">
        {{ title }}
        <button
          v-if="!readonly"
          type="button"
          class="btnClose"
          @click="close"
          aria-label="Close PromoPopup"
        >
          <img src="/images/close.png" class="mt-n5" />
        </button>
      </section>
      <div style="clear: both"></div>
      <section
        class="PromoPopup-body"
        :class="fontSize ? 'normal' : ''"
        id="PromoPopupDescription"
        v-html="body"
      ></section>

      <section class="PromoPopup-footer">
        <footer v-if="footer=='stake' || footer=='unstake'">
          <button @click="close" class="btn-black">Cancel</button>
          <button @click="confirmStake" class="btn-yellow">I understand</button>
        </footer>
        <footer v-else-if="footer" v-html="footer"></footer>
        <footer v-else-if="closeButton && !readonly">
          <router-link :to="'/scg-staking'" class="btn-blue"  @click="close">STAKE SCG</router-link>
          <div class="btn-black" @click="close">CLOSE</div>
        </footer>
      </section>
    </div>
  </div>
</template>

<script>
export default {
  name: "PromoPopup",
  props: [
    "title",
    "body",
    "state",
    "footer",
    "fontSize",
    "readonly",
    "closeButton",
  ],
  data() {
    return {
      backdrop: false,
      classState: false,
      okStake: String,
      closeStake: String
    };
  },
  watch: {
    state: function (newState, oldState) {
      console.log(oldState+' > '+newState)

      var t = this;

      if (newState) {
        setTimeout(function () {
          t.backdrop = true;
        }, 500);
        setTimeout(function () {
          t.classState = true;
        }, 550);
      } else if (newState != oldState) {

        setTimeout(function () {
          t.backdrop = false;
        }, 500);
        setTimeout(function () {
          t.classState = false;
        }, 550);
      }
    },
  },
  methods: {
    close() {
      this.backdrop = true;
      this.$emit("close");
      this.$nextTick(() => {
        this.classState = false;
        setTimeout(() => (this.backdrop = false), this.isMobile() ? 501 : 0);
      });
    },
    isMobile() {
      return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
        navigator.userAgent
      );
    },
    confirmStake() {
      this.$emit('confirmStake');
    },
    cancelStake() {
      this.$emit('cancelStake');
    }
  },
};
</script>

<style>
.PromoPopup-backdrop {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: rgba(0, 0, 0, 0.7);
  display: flex;
  justify-content: center;
  padding-top: 10vh;
  z-index: 999;
}

.PromoPopup {
  position: absolute;
  width: 600px;
  max-width: 100vw;
  background: #ffffff;
  overflow: hidden;
  display: block;
  margin: 0 auto;
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
  border-bottom-left-radius: 0px;
  border-bottom-right-radius: 0px;
  bottom: 0;
  max-height: 0vw;
  transition: all 0.5s ease-out;
}

.PromoPopup-backdrop.desktop .PromoPopup {
  bottom: auto;
  max-height: auto;
  transition: none;
  border-bottom-left-radius: 20px;
  border-bottom-right-radius: 20px;
}

.PromoPopup.slideIn {
  max-height: 600px;
}

.PromoPopup.slideOut {
  max-height: 0vw;
}

.PromoPopup-header {
  padding: 15px 30px 10px 30px;
  display: flex;
  text-transform: capitalize;
  position: relative;
  color: #262626;
  justify-content: space-between;
  background: #ffe81c;
  border-bottom: 1px solid #f1da0e;
  font-size: 20px;
  font-weight: bold;
}

.PromoPopup-body {
  position: relative;
  padding: 20px 30px 0px 30px;
  text-align: left;
  max-height: 70vh;
  overflow-y: auto;
  font-size: 14px;
  color: #262626;
  background: #ffe81c;
}

.PromoPopup-footer {
  padding: 15px 30px 30px 30px;
  background: #ffe81c;
}

.btnClose {
  position: absolute;
  top: 0px;
  right: -0.1px;
  border: none;
  font-size: 26px;
  padding: 5px 30px;
  cursor: pointer;
  font-weight: bold;
  color: #262626;
  background: #ffe81c;
  opacity: 100;
  border-radius: 0px;
  border-top-right-radius: 20px;
}

.btn-yellow {
  font-weight: 500 !important;
  background: #FBE417;
  border: 2px solid #262626;
  color: #262626;
  border-radius: 5px;
  padding: 10px 20px;
  cursor: pointer;
  margin-left: 5%;
}

.btn-blue {
  color: white !important;
  font-weight: 500 !important;
  background: #1f7388;
  /* border: 1px solid #262626; */
  border-radius: 5px;
  padding: 10px 20px;
  cursor: pointer;
  margin-right: 28px;
}

.btn-black {
  font-weight: 500 !important;
  color: white;
  background: #262626;
  border: 1px solid #262626;
  border-radius: 5px;
  cursor: pointer;
  display: inline;
  padding: 10px 20px;
}

.PromoPopup-body.normal {
  font-size: 14px;
}

.p-bottom {
  bottom: 0px;
}
</style>