<template>
  <div v-if="display == 'payoutTablePlinko'">
    <ul class="aboutList">
      <li>
        <span style="font-size: 1.5em"
          >Bellow you can find the game coefficients</span
        >
      </li>
      <li>
        <span style="font-size: 1.5em"
          >8 Rows</span
        >
      </li>
      <li v-for="(item, index) in coeffsTable8" :key="index" ref="itemColumn">
        <span style="color: #5dbaee; font-size: 1.2em"
          >{{ item.mode }} mode coeffs</span
        >
        <br />
        <div v-for="(subitem, index) in item.coeffs" :key="index" ref="itemColumn">
          <div class="coeff">
            {{ subitem }}
          </div>
        </div>
        <br /><br />
      </li>
      <li><div class="line"></div></li>
      <li>
        <span style="font-size: 1.5em"
          >12 Rows</span
        >
      </li>
      <li v-for="(item, index) in coeffsTable12" :key="index" ref="itemColumn">
        <span style="color: #5dbaee; font-size: 1.2em"
          >{{ item.mode }} mode coeffs</span
        >
        <br />
        <div v-for="(subitem, index) in item.coeffs" :key="index" ref="itemColumn">
          <div class="coeff">
            {{ subitem }}
          </div>
        </div>
        <br /><br />
      </li>
      <li><div class="line"></div></li>
      <li>
        <span style="font-size: 1.5em"
          >16 Rows</span
        >
      </li>
      <li v-for="(item, index) in coeffsTable16" :key="index" ref="itemColumn">
        <span style="color: #5dbaee; font-size: 1.2sem"
          >{{ item.mode }} mode coeffs</span
        >
        <br />
        
        <div v-for="(subitem, index) in item.coeffs" :key="index" ref="itemColumn">
          <div class="coeff">
            {{ subitem }}
          </div>
        </div>
        <br /><br />
      </li>
      <li><div class="line"></div></li>
      <li>And RTP is <span style="color: #92d299">99%</span></li>
    </ul>
  </div>
</template>
<script>
export default {
  props: ["display"],
  data() {
    var coeffsTable8 = [
       { mode: "LOW", coeffs:  [5.6, 2.1, 1.1, 1, 0.5, 1, 1.1, 2.1, 5.6]},
       { mode: "MEDIUM", coeffs:  [13, 3, 1.3, 0.7, 0.4, 0.7, 1.3, 3, 13]},
       { mode: "HIGH", coeffs:  [29, 4, 1.5, 0.3, 0.2, 0.3, 1.5, 4, 29]}
    ]

    var coeffsTable12 = [
       { mode: "LOW", coeffs:  [10, 3, 1.6, 1.4, 1.1, 1, 0.5, 1, 1.1, 1.4, 1.6, 3, 10]},
       { mode: "MEDIUM", coeffs:  [33, 11, 4, 2, 1.1, 0.6, 0.3, 0.6, 1.1, 2, 4, 11, 33]},
       { mode: "LOW", coeffs:  [170, 24, 8.1, 2, 0.7, 0.2, 0.2, 0.2, 0.7, 2, 8.1, 24, 170]},
    ]

    var coeffsTable16 = [
        { mode: "LOW", coeffs: [16, 9, 2, 1.4, 1.4, 1.2, 1.1, 1, 0.5, 1, 1.1, 1.2, 1.4, 1.4, 2, 9, 16]},
        { mode: "MEDIUM", coeffs: [110, 41, 10, 5, 3, 1.5, 1, 0.5, 0.3, 0.5, 1, 1.5, 3, 5, 10, 41, 110]},
        { mode: "HIGH", coeffs: [999, 130, 26, 9, 4, 2, 0.2, 0.2, 0.2, 0.2, 0.2, 2, 4, 9, 26, 130, 999]},
    ]

    return {
      coeffsTable8,
      coeffsTable12,
      coeffsTable16,
    };
  },
};
</script>
<style scoped>

.line{
  width: 100%;
  height: 3px;
  background: #ccc;
}

.aboutList {
  margin: 0px;
  padding: 0px;
  text-align: left;
  list-style-type: none;
  padding-top: 10px;
}
.aboutList li {
  padding: 15px;
  line-height: 25px;
}

.coeff{
  float: left;
  padding: 3px;
  font-size: 0.8rem;
}

</style>