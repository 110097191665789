<template>
  <div class="container-fluid p-0 m-0">
    <Header />
    <div class="container-fluid bg-color-middle">
      <Balance
        :leaderboardPlace="leaderboardPlace"
        :leaderboardDate="leaderboardDate"
      />
    </div>
    <div class="max-800" style="margin: 0 auto">
      <router-view></router-view>
    </div>
    <Footer
      v-if="leaderboard"
      :leaderboard="leaderboard"
      :leaderboardDate="leaderboardDate"
    />
  </div>
</template>

<script>
import Api from "@/services/api.js";
import Header from "./components/Header.vue";
import Balance from "./components/Balance.vue";
import Footer from "./components/Footer.vue";

export default {
  name: "App",
  components: {
    Header,
    Balance,
    Footer,
  },
  data() {
    return {
      leaderboard: false,
      leaderboardPlace: 0,
      leaderboardDate: false,
      drawerTitleAddon: false,
      intervalId: false,
      months3: {
        "01": "JAN",
        "02": "FEB",
        "03": "MAR",
        "04": "APR",
        "05": "MAY",
        "06": "JUN",
        "07": "JUL",
        "08": "AUG",
        "09": "SEP",
        10: "OCT",
        11: "NOV",
        12: "DEC",
      },
    };
  },
  mounted() {
    this.getLeaderboard();
  },
  watch: {
    "$store.state.user.wallet": function () {
      this.getLeaderboard(false, true);
    },
  },
  methods: {
    getLeaderboard(leaderboardId, forceReload) {
      clearInterval(this.intervalId);
      if (!leaderboardId) {
        this.intervalId = setInterval(this.getLeaderboard, 60 * 1000);
      }

      if (!forceReload) {
        let apiLastCall = localStorage.getItem("api");
        if (apiLastCall) {
          if (Date.now() - apiLastCall < 60000) {
            let lb = localStorage.getItem("l");
            if (lb) {
              lb = JSON.parse(lb);
              this.leaderboard = lb.l;
              this.drawerTitleAddon = lb.d;
              this.leaderboardDate = lb.ld;
              if (lb.p) {
                this.leaderboardPlace = lb.p;
              }
            }
            return;
          }
        }
      }

      Api.getLeaderboard(this.$store.getters.getUserWallet, leaderboardId).then(
        ({ data }) => {
          localStorage.setItem("api", Date.now());
          let records = !data ? 0 : data.top.length;
          let places = ++data.data.size;
          let [year1, month1, day1] = data.data.end.split(" ")[0].split("-");
          let dateTo = day1.replace(/^0+/, "") + " " + this.months3[month1];
          let [year2, month2, day2] = data.data.start.split(" ")[0].split("-");
          console.log(year1 + year2);
          let dateFrom = day2.replace(/^0+/, "") + " " + this.months3[month2];
          this.drawerTitleAddon =
            `<span style="display:inline-block;font-size: 14px;top: -1px;position: relative;color:#ffe81c">` +
            dateFrom +
            ` - ` +
            dateTo+
            `</span>`;

          this.leaderboardDate = dateTo + "-" + dateFrom;

          if (records < data.data.size) {
            let pos = ++records;
            while (pos < places) {
              data.top.push({
                place: pos,
                player: "-----",
                bet: "-----",
                percent: "-----",
                prize: "-----",
              });
              ++pos;
            }
          }
          this.leaderboard = data;
          this.leaderboardPlace = this.leaderboard.player ? this.leaderboard.player.place : 0;
            
          localStorage.setItem(
            "l",
            JSON.stringify({
              d: this.drawerTitleAddon,
              ld: this.leaderboardDate,
              l: this.leaderboard,
              p: this.leaderboardPlace,
            })
          );
        }
      );
    },
  },
};
//https://www.theiconof.com/search
</script>
<style>
@import "./assets/vendor/icon-set/style.css";
@import "./assets/vendor/rsslider/rs.css";
@font-face {
  font-family: "Noto Sans";
  src: url("./assets/fonts/web/NotoSans-Bold.eot");
  src: url("./assets/fonts/web/NotoSans-Bold.eot?#iefix")
      format("embedded-opentype"),
    url("./assets/fonts/web/NotoSans-Bold.woff2") format("woff2"),
    url("./assets/fonts/web/NotoSans-Bold.woff") format("woff"),
    url("./assets/fonts/web/NotoSans-Bold.svg#NotoSans-Bold") format("svg");
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Noto Sans Regular";
  src: url("./assets/fonts/web/NotoSans.eot");
  src: url("./assets/fonts/web/NotoSans.eot?#iefix") format("embedded-opentype"),
    url("./assets/fonts/web/NotoSans.woff2") format("woff2"),
    url("./assets/fonts/web/NotoSans.woff") format("woff"),
    url("./assets/fonts/web/NotoSans.svg#NotoSans") format("svg");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

html,
body,
#app {
  font-family: "Noto Sans";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  background: #f3f5f7;
}

a,
a:visited {
  color: #262626;
  text-decoration: none;
}

a:hover {
  color: #a3a3a3;
}

.container article {
  padding: 40px 0px;
  justify-content: left;
  text-align: justify;
  font-weight: 400 !important;
  font-family: "Open Sans", sans-serif;
}

.container article ul,
.container article ol {
  margin: 0px;
  padding-right: 15px;
}

.container article ul,
.container article ul li ol {
  padding-bottom: 20px;
}

ul.no-bullets,
ol.no-bullets {
  list-style-type: none;
  padding: 0px;
  padding-right: 0px !important;
}

.container article h1 {
  font-size: 1.7rem;
}

.container article h2 {
  font-size: 1.2rem;
}

.container article ol {
  list-style-type: lower-alpha;
}
.icons {
  background: #ffe81c;
  width: 1.5em;
  height: 1.5em;
  border-radius: 1.5em;
}
.text-yellow {
  color: #ffe81c;
}
.bg-yellow {
  background-color: #ffe81c;
}
.text-dark {
  color: #262626;
}
.bg-dark {
  background-color: #262626;
}
.bg-opacity {
  opacity: 0.9;
}
.text-hint {
  font-size: 10px;
  font-weight: normal !important;
  color: #a3a3a3;
}
.bg-color-middle {
  background-color: #f5f5f5 !important;
}
.float-left {
  float: left;
}
.float-right {
  float: right;
}
.max-800 {
  max-width: 800px;
}
@media (max-width: 992px) {
  .truncate {
    display: block;
    width: 60px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}
.container-fluid {
  max-width: 99.9% !important;
}
</style>