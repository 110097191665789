<template>
  <div v-if="display == 'baccaratRules'" style="padding: 0px 8px">
    <h6>Gameplay</h6>
    <ol>
      <li v-for="(children, item) in list1" :key="item">
        {{ item }}
        <template v-if="typeof children == 'object'">
          <ul>
            <li v-for="(child, index) in children" :key="index">{{ child }}</li>
          </ul>
        </template>
      </li>
    </ol>
    <h6>Player’s Third Card Rule Table</h6>
    <table>
      <tr>
        <th>Total of Player's First Two Cards</th>
        <th>Action for Player</th>
      </tr>
      <tr v-for="(item, index) in table1" :key="index">
        <td>{{ index }}</td>
        <td>{{ item }}</td>
      </tr>
    </table>

    <h6>Dealer’s Third Card Drawing Rule</h6>
    <ul>
      <li v-for="(item, index) in list2" :key="index">{{ item }}</li>
    </ul>

    <h6>Dealer’s Third Card Rule Summary Table</h6>
    <table class="large">
      <tr>
        <th>Banker's Points</th>
        <th>Player's Third Card</th>
        <th v-for="item in 10" :key="item"></th>
      </tr>
      <tr v-for="(item, index) in table2" :key="index">
        <td v-for="(entry, idx) in item" :key="idx">{{entry}}</td>
      </tr>
    </table>
    N= Player Draws No Card, D= Draw Card, S= Stands. No Third Card is Drawn
  </div>
</template>
<script>
export default {
  props: ["display"],
  data() {
    return {
      list1: {
        "You place your chips on either the Player, Banker, or for a tie in the relevant betting box in front of you on the table. No cards are dealt until all bets are placed.": null,
        "In the first round the Player receives two cards face up in the Player box, then the Banker receives two cards face up in the Banker box on the table from a virtual dealer.": null,
        "If either the Banker or the Player has an 8 or 9, which is called a natural hand, the closest to 9 wins and the hand is over, and you receive money for the bets you’ve won.": null,
        "If both Player and Banker have the same amount of points, tie bets win.": null,
        "If nobody has a natural or a tie, the value of the player’s hand dictates what happens next:": [
          "If the player has 7 or 6, he stands.",
          "If the player has 5 to 0, he’ll get another card added to the total hand value and this finishes the player's hand.",
        ],
      },
      table1: {
        "0-5": "Draws a Third Card",
        "6-7": "Stand",
        "8-9": "Stands on a Natural - Hand over",
      },
      list2: [
        "If the dealer has 7, 8, or 9 he stands",
        "If the dealer has 6 – and the player’s third card was 6-7– he takes another card",
        "If the dealer has 5 – and the player’s third card was 4-7– he takes another card",
        "If the dealer has 4 – and the player’s third card was 2-7– he takes another card",
        "If the dealer has 3 – and the player’s third card was anything but 8 – he takes another card",
        "If the dealer has 0-2 he takes another card",
      ],
      table2: [
          ["","N","0","1","2","3","4","5","6","7","8","9"],
          ["9","S","S","S","S","S","S","S","S","S","S","S"],
          ["8","S","S","S","S","S","S","S","S","S","S","S"],
          ["7","S","S","S","S","S","S","S","S","S","S","S"],
          ["6","S","S","S","S","S","S","S","D","D","S","S"],
          ["5","D","S","S","S","S","D","D","D","D","S","S"],
          ["4","D","S","S","D","D","D","D","D","D","S","S"],
          ["3","D","D","D","D","D","D","D","D","D","S","D"],
          ["2","D","D","D","D","D","D","D","D","D","D","D"],
          ["1","D","D","D","D","D","D","D","D","D","D","D"],
          ["0","D","D","D","D","D","D","D","D","D","D","D"],
      ]
    };
  },
};
</script>
<style scoped>
h6 {
  text-decoration: underline;
  margin-top: 30px;
}
ol li,
ul li {
  line-height: 30px;
  font-family: "Noto Sans Regular";
  font-size: 14px;
}
table {
    width: 100%;
    text-align: center;
}
table th {
  font-weight: bold;
}
table,
th,
td {
  border: 1px solid #c0c0c0;
  border-collapse: collapse;
  font-weight: normal;
  font-family: "Noto Sans Regular";
  font-size: 14px;
}
</style>
