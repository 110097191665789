<template>
  <div v-if="display == 'howToPlayKeno'">
    <ul class="howToPlayList">
      <li>
        Select how many spots (numbers) you would like to play (up to 10).
        Choose your own spots (numbers) from (1 through 40) or use the Quick Pik
        option and let the computer select for you. Selected numbers will be highlighted <span style="color: #eecf16">YELLOW</span> color
        <br />
        <img src="@/assets/img/selectedboard.png" />
      </li>
      <li>
        Choose your play amount. You can play for {{minBet}} TT up to {{maxBet}} TT
        <br />
        <img src="@/assets/img/selectamount.png" />
      </li>
      <li>
        Coefficients are shown in the right side of table
        <br />
        <img src="@/assets/img/coeffs.png" />
      </li>
      <li>
        After success bet game will highlight incoming numbers with
        <span style="color: #5dbaee">BLUE</span> color, intersected numbers with
        <span style="color: #92d299">GREEN</span> color, and your selected
        numbers is <span style="color: #eecf16">YELLOW</span> color.
        <br />
        And win coefficient on left panel with
        <span style="color: #92d299">GREEN</span> color
        <br />
        <img src="@/assets/img/bet.png" />
      </li>
      <li>That's all you need to know</li>
    </ul>
    <br />
  </div>
</template>
<script>
export default {
  props: ["display"],
  data(){
    return {
      minBet: this.$store.getters.getGameLimits['keno'].min,
      maxBet: this.$store.getters.getGameLimits['keno'].max
    }
  }
};
</script>
<style scoped>
.howToPlayList {
  margin: 0px;
  padding: 0px;
  text-align: left;
  list-style-type: none;
  padding-top: 10px;
}
.howToPlayList li {
  padding: 15px;
  line-height: 25px;
}
</style>