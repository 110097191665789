<template>
  <div v-if="display == 'howToPlayVideopoker'">
    <ul class="howToPlayList">
      <li>
        Select bet amount and click play button to make a bet
        <br />
        <img src="@/assets/img/videopokerbetarea.png" />
      </li>
      
      <li>
        And game will deal first 5 cards
        <br />
        <img src="@/assets/img/videopokerdeal1.png" />
      </li>
      <li>
        After you can lock the cards you want, they will highlight yellow, and press play button again
        <br />
        <img src="@/assets/img/videopokerhighlight.png" />
      </li>
      <li>
        Game will save you locked cards,redeal others and highlight win combination 
        <br />
        <img src="@/assets/img/videopokerwin.png" />
      </li>
      <li>That's all you need to know </li>
    </ul>
    <br />
  </div>
</template>
<script>
export default {
  props: ["display"],
};
</script>
<style scoped>
.howToPlayList {
  margin: 0px;
  padding: 0px;
  text-align: left;
  list-style-type: none;
  padding-top: 10px;
}
.howToPlayList li {
  padding: 15px;
  line-height: 25px;
}
</style>
