<template>
  <div v-if="display == 'howToPlayPlinko'">
    <ul class="howToPlayList">
      <li>
        Select the mode you want to play. Plinko has three modes LOW MEDIUM and HIGH
        <br />
        <img src="@/assets/img/plinkomode.png" />
      </li>
      
      <li>
        Choose your play amount. You can play for {{minBet}} TT up to {{maxBet}} TT
        <br />
        <img src="@/assets/img/selectamount.png" />
      </li>
      <li>
        After success bet, you will see animated ball falling between points
        <br />
        <img src="@/assets/img/plinkoballfall.png" />
      </li>
      <li>That's all you need to know</li>
    </ul>
    <br />
  </div>
</template>
<script>
export default {
  props: ["display"],
  data(){
    return {
      minBet: this.$store.getters.getGameLimits['plinko'].min,
      maxBet: this.$store.getters.getGameLimits['plinko'].max
    }
  }
};
</script>
<style scoped>
.howToPlayList {
  margin: 0px;
  padding: 0px;
  text-align: left;
  list-style-type: none;
  padding-top: 10px;
}
.howToPlayList li {
  padding: 15px;
  line-height: 25px;
}
</style>
