<template>
  <div v-if="display == 'videopokerRules'" style="padding: 0px 8px">
     <ul class="rulesList">
      <li style="line-height: 25px;">
        Videopoker is a 5 card poker game with same combinations. Below you can find combinations with samples
      </li>
      <li>
        Royal flash
        <br />
        <div class="cardbgselected"><img src="/cardspng/AS.png" class="playcard cardImg" /></div>
        <div class="cardbgselected"><img src="/cardspng/KS.png" class="playcard cardImg" /></div>
        <div class="cardbgselected"><img src="/cardspng/QS.png" class="playcard cardImg" /></div>
        <div class="cardbgselected"><img src="/cardspng/JS.png" class="playcard cardImg" /></div>
        <div class="cardbgselected"><img src="/cardspng/TS.png" class="playcard cardImg" /></div>
        <br />
      </li>
      <li>
        Straight flash
        <br />
        <div class="cardbgselected"><img src="/cardspng/KH.png" class="playcard cardImg" /></div>
        <div class="cardbgselected"><img src="/cardspng/QH.png" class="playcard cardImg" /></div>
        <div class="cardbgselected"><img src="/cardspng/JH.png" class="playcard cardImg" /></div>
        <div class="cardbgselected"><img src="/cardspng/TH.png" class="playcard cardImg" /></div>
        <div class="cardbgselected"><img src="/cardspng/9H.png" class="playcard cardImg" /></div>
        <br />
      </li>
      <li>
        4 of kind
        <br />
        <div class="cardbgselected"><img src="/cardspng/KD.png" class="playcard cardImg" /></div>
        <div class="cardbgselected"><img src="/cardspng/KH.png" class="playcard cardImg" /></div>
        <div class="cardbgselected"><img src="/cardspng/KS.png" class="playcard cardImg" /></div>
        <div class="cardbgselected"><img src="/cardspng/KC.png" class="playcard cardImg" /></div>
        <div class="cardbg"><img src="/cardspng/9H.png" class="playcard cardImg" /></div>
        <br />
      </li>
      <li>
        Full house
        <br />
        <div class="cardbgselected"><img src="/cardspng/9C.png" class="playcard cardImg" /></div>
        <div class="cardbgselected"><img src="/cardspng/9H.png" class="playcard cardImg" /></div>
        <div class="cardbgselected"><img src="/cardspng/KD.png" class="playcard cardImg" /></div>
        <div class="cardbgselected"><img src="/cardspng/KH.png" class="playcard cardImg" /></div>
        <div class="cardbgselected"><img src="/cardspng/KS.png" class="playcard cardImg" /></div>
        <br />
      </li>
      <li>
        Flash
        <br />
        <div class="cardbgselected"><img src="/cardspng/2D.png" class="playcard cardImg" /></div>
        <div class="cardbgselected"><img src="/cardspng/4D.png" class="playcard cardImg" /></div>
        <div class="cardbgselected"><img src="/cardspng/8D.png" class="playcard cardImg" /></div>
        <div class="cardbgselected"><img src="/cardspng/JD.png" class="playcard cardImg" /></div>
        <div class="cardbgselected"><img src="/cardspng/KD.png" class="playcard cardImg" /></div>
        <br />
      </li>
      <li>
        Straight
        <br />
        <div class="cardbgselected"><img src="/cardspng/KH.png" class="playcard cardImg" /></div>
        <div class="cardbgselected"><img src="/cardspng/QC.png" class="playcard cardImg" /></div>
        <div class="cardbgselected"><img src="/cardspng/JH.png" class="playcard cardImg" /></div>
        <div class="cardbgselected"><img src="/cardspng/TD.png" class="playcard cardImg" /></div>
        <div class="cardbgselected"><img src="/cardspng/9H.png" class="playcard cardImg" /></div>
        <br />
      </li>
      <li>
        3 of kind
        <br />
        <div class="cardbgselected"><img src="/cardspng/KD.png" class="playcard cardImg" /></div>
        <div class="cardbgselected"><img src="/cardspng/KH.png" class="playcard cardImg" /></div>
        <div class="cardbgselected"><img src="/cardspng/KS.png" class="playcard cardImg" /></div>
        <div class="cardbg"><img src="/cardspng/QC.png" class="playcard cardImg" /></div>
        <div class="cardbg"><img src="/cardspng/9H.png" class="playcard cardImg" /></div>
        <br />
      </li>
      <li>
        2 pairs
        <br />
        <div class="cardbgselected"><img src="/cardspng/KD.png" class="playcard cardImg" /></div>
        <div class="cardbgselected"><img src="/cardspng/KH.png" class="playcard cardImg" /></div>
        <div class="cardbgselected"><img src="/cardspng/QS.png" class="playcard cardImg" /></div>
        <div class="cardbgselected"><img src="/cardspng/QC.png" class="playcard cardImg" /></div>
        <div class="cardbg"><img src="/cardspng/9H.png" class="playcard cardImg" /></div>
        <br />
      </li>
      <li>
        Jacks or better
        <br />
        <div class="cardbgselected"><img src="/cardspng/KD.png" class="playcard cardImg" /></div>
        <div class="cardbgselected"><img src="/cardspng/KH.png" class="playcard cardImg" /></div>
        <div class="cardbg"><img src="/cardspng/QS.png" class="playcard cardImg" /></div>
        <div class="cardbg"><img src="/cardspng/TC.png" class="playcard cardImg" /></div>
        <div class="cardbg"><img src="/cardspng/9H.png" class="playcard cardImg" /></div>
        <br />
      </li>
    </ul>

  </div>
</template>
<script>
export default {
  props: ["display"],
  data() {
    return {

    };
  },
};
</script>
<style scoped>

.cardbgselected {
  background: #ffe81c;
  float: left;
  padding: 5px;
  height: 71px;
  border-radius: 10px;
  margin: 3px;
}


.cardbg {
  float: left;
  background: white;
  padding: 5px;
  height: 71px;
  border-radius: 10px;
  margin: 3px;
}
.cardImg {
  width: 40px;
}

.playcard {
  mix-blend-mode: darken;
}





.rulesList {
  margin: 0px;
  padding: 0px;
  text-align: left;
  list-style-type: none;
  padding-top: 10px;
}
.rulesList li {
  padding: 15px;
  
}
</style>
