<template>
  <div v-if="display == 'payoutTableKeno'">
    <ul class="aboutList">
      <li>
        <span style="font-size: 1.5em"
          >Bellow you can find the game coefficients</span
        >
      </li>
      <li v-for="(item, index) in coeffsTable" :key="index" ref="itemColumn">
        <span style="color: #5dbaee; font-size: 1.5em"
          >{{ index + 1 }} ball game</span
        >
        <br />
        <div v-for="(subitem, index) in item" :key="index" ref="itemColumn">
          <div v-if="subitem > 0">
            Hit <span style="color: #eecf16">{{ index }}</span> an get
            <span style="color: #92d299">{{ subitem }}x</span> multiplier
            <br />
          </div>
        </div>
      </li>
      <li>And RTP is <span style="color: #92d299">99%</span></li>
    </ul>
  </div>
</template>
<script>
export default {
  props: ["display"],
  data() {
    var coeffsTable = [
    [0.7, 1.85],
    [.0, 2, 3.8],
    [.0, 1.1, 1.38, 26],
    [.0, .0, 2.20, 7.9, 90],
    [.0, .0, 1.5, 4.2, 13, 300],
    [.0, .0, 1.1, 2, 6.20, 100, 700],
    [.0, .0, 1.1, 1.6, 3.5, 15, 225, 700],
    [.0, .0, 1.1, 1.5, 2, 5.5, 39, 100, 800],
    [.0, .0, 1.1, 1.3, 1.7, 2.5, 7.5, 50, 250, 1000],
    [.0, .0, 1.1, 1.2, 1.3, 1.8, 3.5, 13, 50, 250, 1000]
    ];

    return {
      coeffsTable,
    };
  },
};
</script>
<style scoped>
.aboutList {
  margin: 0px;
  padding: 0px;
  text-align: left;
  list-style-type: none;
  padding-top: 10px;
}
.aboutList li {
  padding: 15px;
  line-height: 25px;
}
</style>
