import axios from 'axios'

const instance = axios.create({
    baseURL: process.env.VUE_APP_API_BASE_URL,
    headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json'
    },
    timeout: process.env.VUE_APP_API_TIMEOUT,
})

const instance2 = axios.create({
    baseURL: process.env.VUE_APP_PROVIDER_API_URL,
    headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json'
    },
    timeout: process.env.VUE_APP_API_TIMEOUT,
})

class ApiService {
    currencyRates() {
        return instance.get(process.env.VUE_APP_TT_RATES + "?" + Date.now())
    }
    getVersion() {
        return instance.get("/app.version?" + Date.now())
    }
    getLeaderboard(wallet, leaderboardId) {
        let query = "/leaderboard?";
        if(wallet) {
            query +="player=" + wallet;
        }
        if(leaderboardId) {
            query +="&round=" + leaderboardId;
        }
        return instance2.get(query)
    }
}

const Api = new ApiService;
export default Api