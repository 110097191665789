<template>
  <div v-if="display == 'howToPlayDice'">
    <ul class="howToPlayList">
      <li>
        <h5>Manual Mode</h5>
        Select WinChance by using slider
        <br />
        Switch Under/Over by clicking "Roll Under" button
        <br />
        <img src="@/assets/img/dicemanual.png" />
      </li>
      <li>
        Choose your play amount. You can play for {{ minBet }} TT up to
        {{ maxBet }} TT
        <br />
        And hit the PLAY DICE button
        <br />
        <img src="@/assets/img/dicemanualplay.png" />
      </li>
    </ul>
    <br />
    <h4>Auto Mode</h4>
    <ul class="howToPlayList">
      <li>
        Select Auto by clicking auto button
        <br />
        <img src="@/assets/img/autoselect.png" />
      </li>
      <li>
        Select Win Chance by using slider
        <br />
        And Under/Over by clicking middle button
        <br />
        <img src="@/assets/img/dicemanual.png" />
      </li>
      <li>
        Choose your play amount. You can play for {{ minBet }} TT up to
        {{ maxBet }} TT
        <br />
        <img src="@/assets/img/autoamount.png" />
      </li>
      <li>
        Configure autoplay settings
        <br />
        And hit the START AUTOROLL button
        <br />
        <img src="@/assets/img/autoconfig.png" />
      </li>
      <li>
        You can stop autoroll by hiting the STOP AUTOROLL button
        <br />
        <img src="@/assets/img/stopauto.png" />
      </li>
    </ul>
    <br />
  </div>
</template>
<script>
export default {
  props: ["display"],
  data() {
    return {
      minBet: this.$store.getters.getGameLimits["dice"].min,
      maxBet: this.$store.getters.getGameLimits["dice"].max,
    };
  },
};
</script>
<style scoped>
.howToPlayList {
  margin: 0px;
  padding: 0px;
  text-align: left;
  list-style-type: none;
  padding-top: 10px;
}
.howToPlayList li {
  padding: 15px;
  line-height: 25px;
}
.howToPlayList li img {
  width: 100%;
  max-width: 370px;
}
</style>