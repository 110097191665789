
import Web3 from "web3";


export default class LeaderboardHelper {
    balance = 0
    contract = null
    address = null
    account = null
    Leaderboard = null
    lastBlock = 0
    getLogsDataInterval = 0
    processedTxs = []

    constructor(_messageHandler, _Leaderboard) {
        this.messageHandler = _messageHandler
        this.Leaderboard = _Leaderboard
    }
    getNetworkVersion() {
        var networkV = 0
        try {
            if (window.web3.currentProvider.isViaWallet) {
                networkV = window.web3.currentProvider.chainId
            } else {
                networkV = window.web3.eth.currentProvider.networkVersion
            }
        } catch (error) {
            window.Logger.log(error)
        }

        return networkV
    }

    balanceOf() {
        this.contract.methods
            .balanceOf()
            .call({ from: this.account })
            .then(function (result) {
                this.messageHandler({
                    type: 'leaderboardbalance',
                    address: this.account,
                    amount: Web3.utils.fromWei(result, 'ether')
                })
            }.bind(this), function (params) {
                this.messageHandler({ type: 'error', msg: 'Cashback rejected code:' + params })
            }.bind(this))
    }


    claim() {
        this.contract.methods
            .claim()
            .send({ from: this.account })
            .then(function (result) {
                window.Logger.log(result)
            }.bind(this), function (params) {
                this.messageHandler({ type: 'error', msg: 'Leaderboard rejected code:' + params })
            }.bind(this))
    }

    connect(){
        this.universalSetupPlan()
    }

    universalSetupPlan() {
        if (window.ethereum) {
            if (window.ethereum.isTrust && !window.ethereum.isMetaMask) {
                window.Logger.log('Trust wallet case');
                if (window.web3) {
                    window.Logger.log('Web3 present')
                }

                window.web3 = new Web3(window.ethereum); // dzvel kodshi jer es isetebeoda

                window.ethereum
                .enable() // Gansxvaveba es aris
                .then(function(){
                    window.web3.eth.getAccounts(function (err, accounts) {
                        if (err != null) {
                            window.Logger.log(err);
                            window.Logger.log(err.stack);
                        } else if (!accounts) {
                            window.Logger.log('No accounts')
                        } else if (accounts.length == 0) {
                            window.Logger.log('Accounts length 0')
                            this.messageHandler({ type: 'notconnected' })
                        } else {
                            window.Logger.log("Connected")
                            this.fetchAccount() // es crurcmenis pontshi chavsvat ak ikneb imushavos
                        }
                    }.bind(this));
                }.bind(this))
                .catch(function(err) {
                    this.messageHandler({ type: 'rejected' })
                    if (err.code === 4001) {
                        window.Logger.log('User rejected wallet connection');
                    } else {
                        window.Logger.log(err);
                    }
                });
            } else {

                window.Logger.log('Regular case');

                window.ethereum.request({ method: 'eth_requestAccounts' })
                .then(function(){
                    window.web3 = new Web3(window.ethereum);
                    this.fetchAccount()
                }.bind(this))
                .catch(function(err) {
                    this.messageHandler({ type: 'rejected' })
                    if (err.code === 4001) {
                        window.Logger.log('User rejected wallet connection');
                    } else {
                        window.Logger.log(err);
                    }
                }.bind(this));

            }


        } else {
            window.Logger.log("No ethereum")
            if (this.web3) {
                window.Logger.log("But web3 present")
            }
        }
    }

    fetchAccount() {
        try {
            window.web3.eth.getAccounts().then(
                function (accounts) {
                    window.Logger.log("fetchAccount "+accounts[0]+" "+this.account)
                    this.account = accounts[0];
                    this.onConnet()
                }.bind(this)
            );
        } catch (error) {
            window.Logger.log(error);
        }
    }

    waitForAccount() {
        var accountInterval = setInterval(function () {
            if (this.Leaderboard.networks[this.getNetworkVersion()] != null) {
                clearInterval(accountInterval)
                this.onConnet();
            }
        }.bind(this), 100);
    }


    onConnet() {
        window.Logger.log("onConnet "+this.getNetworkVersion())
        if (this.Leaderboard.networks[this.getNetworkVersion()] == null) {
            this.messageHandler({ type: 'wrongnetwork'})
            window.leaderboardaddress = null
            this.waitForAccount()
            return;
        }
        window.Logger.log("onConnet1")
        window.Logger.log("this.account " + this.account)
        this.address = this.Leaderboard.networks[this.getNetworkVersion()].address;
        this.contract = new window.web3.eth.Contract(
            this.Leaderboard.abi,
            this.address
        );
        console.log(window.leaderboardaddress)
        this.messageHandler({ type: 'connected',msg: this.account })
        if (window.leaderboardaddress) {
            if (window.leaderboardaddress[this.address] == true) {
                return
            }
        }
        if (window.leaderboardaddress == null) {
            window.leaderboardaddress = {}
        }
        window.leaderboardaddress[this.address] = true
        
        window.web3.eth.getBlockNumber().then(function (data) {
            this.lastBlock = data;
            this.getLogsDataInterval = setInterval(this.getLogsData.bind(this), 1000);
        }.bind(this));

        
        this.networkVersion = this.getNetworkVersion()
        this.accountIntervalCheck = setInterval(function () {
            if (this.networkVersion != this.getNetworkVersion()) {
                clearInterval(this.accountIntervalCheck)
                this.networkVersion = this.getNetworkVersion()
                this.networkChanged()
            }
        }.bind(this), 1000);
    }

    networkChanged(){
        if (this.Leaderboard.networks[this.getNetworkVersion()] == null) {
            this.messageHandler({ type: 'wrongnetwork'})
            window.leaderboardaddress = null
            this.waitForAccount()
        }
    }

    destroy() {
        if (window.leaderboardaddress) {
            window.leaderboardaddress[this.address] = false
        }
    }

    getLogsData() {
        if (this.lastBlock > 0) {
            this.contract.events.allEvents({
                fromBlock: this.lastBlock
            }).on('data', function (event) {
                if (this.processedTxs.indexOf(event.transactionHash) == -1) {
                    this.processedTxs.push(event.transactionHash)
                    this.lastBlock = event.blockNumber + 1
                    if (
                        this.account.toUpperCase() == event.returnValues.player.toUpperCase()
                    ) {
                        switch (event.event) {
                            case 'Claim':
                                this.messageHandler({
                                    type: 'leaderboardclaim',
                                    address: event.returnValues.player,
                                    amount: Web3.utils.fromWei(event.returnValues.balance, 'ether')
                                })
                                break
                            default:
                                break;
                        }
                    }
                }

                if (this.processedTxs.length > 30) {
                    this.processedTxs.pop()
                }

            }.bind(this))
        }
    }
}

