<template>
  <div>
    <ul class="drawerMenu">
      <li
        v-for="(item, idx) in menuList"
        v-bind:key="idx"
        :class="[item.to == '#' ? 'none' : 'mb-3']"
      >
        <template v-if="item.target">
          <i :class="item.iconClass"></i>
          <a :href="item.target" target="_new">{{ item.name }}</a>
        </template>
        <router-link
          v-else
          to="#"
          @click="
            item.to
              ? closeDrawer(item.to)
              : pageView(item.page, item.name, item.class)
          "
          :id="'drawer_link_' + idx"
        >
          <i :class="item.iconClass"></i>
          <span :class="'menuItem ' + [item.to == '#' ? 'soon' : '']">{{
            item.name
          }}</span>
        </router-link>
        <div v-if="item.to == '#'" class="soonIcon">COMMING SOON</div>
      </li>

      <li class="mb-3">
        <router-link to="#" @click="closeDrawer('/scg-staking')">
          <i class="tio-coin drawerMenuIcon"></i>SCG Staking
          <sup style="color: red">NEW</sup>
        </router-link>
      </li>

      <li class="mb-3">
        <a
          href="https://www.sushi.com/swap?chainId=108&token0=NATIVE&token1=0x71CF496Fc5Bc3041496421F09b069BC1cf6c98Db"
        >
          <i class="tio-coin drawerMenuIcon"></i>Buy SCG Token
          <sup style="color: red">NEW</sup>
        </a>
      </li>

      <li class="mb-3">
        <router-link
          to="#"
          @click="pageView('leaderboard', 'Leaderboard', 'none')"
          :id="'drawer_link_leaderboard'"
        >
          <i class="tio-medal drawerMenuIcon"></i>Leaderboard
        </router-link>
      </li>

      <li class="mb-3">
        <router-link to="#" @click="closeDrawer('/roadmap')">
          <i class="tio-document-outlined drawerMenuIcon"></i>SCGames Roadmap
        </router-link>
      </li>

      <li class="mb-3">
        <a
          :href="'mailto:' + appProviderEmail"
          target="_top"
          rel="noopener noreferrer"
          ><i class="tio-email-outlined drawerMenuIcon"></i
          >{{ appProviderEmail }}</a
        >
      </li>

      <li class="mb-3">
        <a :href="appTelegram" target="_telegram"
          ><i class="tio-telegram drawerMenuIcon"></i
          >{{ appTelegram.replace("https://", "") }}
        </a>
      </li>

      <li class="mb-3">
        <a :href="appTwitter" target="_twitter"
          ><i class="tio-twitter drawerMenuIcon"></i
          >{{ appTwitter.replace("https://", "") }}
        </a>
      </li>
    </ul>

    <div class="pageDrawer" :class="pageDrawerVisible ? 'pageOpen' : ''">
      <div id="pageHeader">
        <div class="container max-800">
          <div id="infoHolder">
            <i :class="pageIcon"> </i>
            {{ pageTitle }}
            <span
              v-if="pageTitle === 'Leaderboard'"
              v-html="$root.drawerTitleAddon"
            ></span>
          </div>
        </div>
        <div id="closeHolder" @click="pageDrawerVisible = false">
          <img src="@/assets/img/close.svg" id="closePage" />
        </div>
        <div class="clearfix"></div>
      </div>
      <div class="container text-start mt-5 pt-3 max-800">
        <HowToPlayKeno :display="pageDrawerVisible" />
        <AboutKeno :display="pageDrawerVisible" />
        <PayoutTableKeno :display="pageDrawerVisible" />

        <HowToPlayPlinko :display="pageDrawerVisible" />
        <AboutPlinko :display="pageDrawerVisible" />
        <PayoutTablePlinko :display="pageDrawerVisible" />

        <HowToPlayBaccarat :display="pageDrawerVisible" />
        <AboutBaccarat :display="pageDrawerVisible" />
        <BaccaratRules :display="pageDrawerVisible" />

        <HowToPlayLucky777 :display="pageDrawerVisible" />
        <AboutLucky777 :display="pageDrawerVisible" />
        <Lucky777Payout :display="pageDrawerVisible" />

        <HowToPlayVideopoker :display="pageDrawerVisible" />
        <AboutVideopoker :display="pageDrawerVisible" />
        <VideopokerRules :display="pageDrawerVisible" />

        <HowToPlayDice :display="pageDrawerVisible" />
        <AboutDice :display="pageDrawerVisible" />

        <Leaderboard :display="pageDrawerVisible" />
      </div>
    </div>
  </div>
</template>
<script>
import HowToPlayKeno from "@/games/keno/pages/HowToPlay";
import AboutKeno from "@/games/keno/pages/About";
import PayoutTableKeno from "@/games/keno/pages/PayoutTable";

import HowToPlayPlinko from "@/games/plinko/pages/HowToPlay";
import AboutPlinko from "@/games/plinko/pages/About";
import PayoutTablePlinko from "@/games/plinko/pages/PayoutTable";

import HowToPlayBaccarat from "@/games/baccarat/pages/HowToPlay";
import AboutBaccarat from "@/games/baccarat/pages/About";
import BaccaratRules from "@/games/baccarat/pages/BaccaratRules";

import HowToPlayLucky777 from "@/games/lucky777/pages/HowToPlay";
import AboutLucky777 from "@/games/lucky777/pages/About";
import Lucky777Payout from "@/games/lucky777/pages/PayoutTable";

import HowToPlayVideopoker from "@/games/videopoker/pages/HowToPlay";
import AboutVideopoker from "@/games/videopoker/pages/About";
import VideopokerRules from "@/games/videopoker/pages/VideopokerRules";

import HowToPlayDice from "@/games/dice/pages/HowToPlay";
import AboutDice from "@/games/dice/pages/About";

import Leaderboard from "@/components/LeaderboardW";
export default {
  components: {
    HowToPlayKeno,
    AboutKeno,
    PayoutTableKeno,
    HowToPlayPlinko,
    AboutPlinko,
    PayoutTablePlinko,
    HowToPlayBaccarat,
    AboutBaccarat,
    BaccaratRules,
    HowToPlayLucky777,
    AboutLucky777,
    Lucky777Payout,
    HowToPlayVideopoker,
    AboutVideopoker,
    VideopokerRules,
    HowToPlayDice,
    AboutDice,
    Leaderboard,
  },
  data() {
    return {
      addonTitle: null,
      appProviderEmail: process.env.VUE_APP_PROVIDER_EMAIL,
      menuList: [],
      pageDrawerVisible: false,
      pageTitle: "",
      pageIcon: "",
      appTelegram: process.env.VUE_APP_TELEGRAM,
      appTwitter: process.env.VUE_APP_TWITTER,
    };
  },
  methods: {
    closeDrawer(to) {
      this.pageDrawerVisible = false;
      this.$parent.drawerVisible = false;
      return this.$router.push(to);
    },
    pageView: function (page, title, icon) {
      this.pageDrawerVisible = page;
      this.pageTitle = title;
      this.pageIcon = icon;
    },
    setAddonTitle(content) {
      this.addonTitle = content;
    },
  },
  watch: {
    "$route.meta.menu": function () {
      this.menuList = this.$route.meta.menu;
    },
  },
};
</script>