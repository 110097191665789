<template>
  <div v-if="display == 'aboutDice'">
    <ul class="aboutList">
      <li>
        Dice is a blockchain based game deployed on
        <a style="color: #eecf16" :href="blockchain_url" target="_blank">{{
          blockchain_name
        }}</a>
        Blockchain
      </li>
      <li>
        Which means that <span style="color: #5dbaee">{{ domain }}</span> do not
        control the output of bet, and cannot block or reject your bet. Winnings
        are immediately transferred to your address.
      </li>
      <li>
        <span style="color: #5dbaee">{{ domain }}</span> also cannot control any
        multipliers because the multiplier numbers are hardcoded in the smart
        contract
      </li>
      <li>
        You can find the smart contract at address
        <a
          style="color: #eecf16; font-size: 0.8em"
          :href="scanner + contract"
          target="_blank"
          >{{ contract }}</a
        >
      </li>
      <li>Game RTP is <span style="color: #92d299">99%</span></li>
    </ul>
  </div>
</template>
<script>
export default {
  props: ["display"],
  data() {
    return {
      domain: process.env.VUE_APP_PROVIDER,
      blockchain_url: this.$store.getters.getConfig.blockchain_url,
      blockchain_name: this.$store.getters.getConfig.blockchain_name,
      scanner: this.$store.getters.getConfig.scanner,
      contract: this.$store.getters.getConfig.games["dice"].contract,
    };
  },
  watch: {
    display: function () {
      this.contract = this.$store.getters.getConfig.games["dice"].contract;
    }
  },
};
</script>
<style scoped>
.aboutList {
  margin: 0px;
  padding: 0px;
  text-align: left;
  list-style-type: none;
  padding-top: 10px;
}
.aboutList li {
  padding: 15px;
  line-height: 25px;
}
</style>