<template>
  <footer class="footer pb-0">
    <div class="site-section wallets">
      <ul>
        <li><img src="@/assets/img/wallets/polygon-c.svg" /></li>
        <li><img src="@/assets/img/wallets/csc-c.svg" /></li>
        <li><img src="@/assets/img/wallets/thundercore-c.svg" /></li>
        <li><img src="@/assets/img/wallets/metamask-w.svg" /></li>
        <li><img src="@/assets/img/wallets/thundercore-w.svg" /></li>
        <li><img src="@/assets/img/wallets/coinbase-w.svg" /></li>
        <li><img src="@/assets/img/wallets/trust-w.svg" /></li>
        <li><img src="@/assets/img/wallets/safepal-w.svg" /></li>
        <li><img src="@/assets/img/wallets/via-w.svg" /></li>
      </ul>
      <hr />
      <div class="mb-3">
        <a :href="appTelegram" target="_telegram"
          ><i class="me-2 socIcon tio-telegram"></i>
        </a>
        <a :href="appTwitter" target="_twitter"
          ><i class="socIcon tio-twitter"></i>
        </a>
      </div>
    </div>
    <div class="site-section">
      <div class="container">
        <div class="row">
          <div class="col-12">
            <div class="footer-menu-wrap d-md align-items-center">
              <ul
                v-for="block in footerLinks"
                class="list-unstyled footer-menu mr-auto"
                :key="block"
              >
                <li v-for="item in block" :key="item.name">
                  <router-link v-if="item.to" :to="item.to">{{
                    item.name
                  }}</router-link>
                  <a v-else :href="item.target" target="_new">
                    {{ item.name }}</a
                  >
                </li>
              </ul>
            </div>
            <div id="copy">
              Copyright &copy; {{ appCopyDate }}
              <a :href="appProviderUrl" class="text-uppercase">{{
                appProviderDomain
              }}</a>
              <span class="mobileOnly"
                ><sup>{{ appVersion }}</sup></span
              >
            </div>
            <div class="clearfix"></div>
            <sup class="desktopOnly mb-3 mt-1">{{ appVersion }}</sup>
          </div>
        </div>
      </div>
    </div>
    <PromoPopup
      v-if="leaderboard && $route.path !== '/leaderboard'"
      :state="botPopup.state"
      @close="closePopup"
      :title="botPopup.title"
      :body="botPopup.body"
      :closeButton="true"
      fontSize="normal"
    />
  </footer>
</template>

<script>
import Api from "@/services/api.js";
import PromoPopup from "@/components/PromoPopup.vue";
import { store } from "@/store";

export default {
  props: ["leaderboard", "leaderboardDate"],
  name: "footerBar",
  components: {
    PromoPopup,
  },
  data() {
    return {
      appVersion: localStorage.getItem("version"),
      appProviderDomain: process.env.VUE_APP_PROVIDER_DOMAIN,
      appProviderUrl: process.env.VUE_APP_PROVIDER_URL,
      appCopyDate: process.env.VUE_APP_COPY_DATE,
      appTelegram: process.env.VUE_APP_TELEGRAM,
      appTwitter: process.env.VUE_APP_TWITTER,
      footerLinks: this.getLinks,
      botPopup: {
        state: false,
        title: "SCG MINING STARTED",
        body:
          `<div style="font-size:17px;font-weight:600;">
          SCG Token mining contest just started! For a limited time 0.1% from each lost bet is converted to SCG token and is rewarded to a player (For example if you loose 10,000 TT in Dice you will get 10 SCG as a reward). Be aware that mining rate will eventually dicrease and 0.1% rate will be all time high.
          <hr>
          The weekly leaderboard round ends on ` +
          this.leaderboardDate.split("-")[0] +
          `, with a prize pool of ` +
          this.$root.leaderboard.data.pool
            .toString()
            .replace(/\B(?=(\d{3})+(?!\d))/g, ",") +
          ` TT. You're currently #` +
          this.getPlayerPlace() +
          `!<div><hr>`,
      },
    };
  },


  // <hr>
  //         Receive 5 TT instant reward from our telegram bot 
  //         https://t.me/scgames_io_bot for spreading news about Leaderboard 
  //         prize pool on Twitter  

  mounted() {
    this.botPopup.state = store.getters.getConfig.bonus_popup;
    Api.getVersion().then(({ data }) => {
      if (localStorage && localStorage.getItem("version") !== data) {
        localStorage.setItem("version", data);
        window.location.reload(true);
      }
    });
  },
  watch: {
    $route: function () {
      this.footerLinks = this.getLinks();
    },
  },
  methods: {
    getPlayerPlace() {
      return Object.is(this.$root.leaderboard.player, null)
        ? "0"
        : this.$root.leaderboard.player.place;
    },
    getLinks() {
      return [
        [
          {
            name: "About SCGames",
            to: null,
            target: "https://gitbook.scgames.io/",
          },
          {
            name: "SCG Staking & Dividends",
            to: "/scg-staking",
            target: null,
          },
          {
            name: "Buy SCG Token",
            to: null,
            target: "https://www.sushi.com/swap?chainId=108&token0=NATIVE&token1=0x71CF496Fc5Bc3041496421F09b069BC1cf6c98Db",
          },
          {
            name: "SCG Roadmap",
            to: "/roadmap",
            target: null,
          },
          {
            name: "Blog",
            to: null,
            target: "https://blog.scgames.io",
          },
        ],
        [
          {
            name: "About " + this.$store.getters.getCurrency,
            to: "/about",
            target: null,
          },
          {
            name: "Terms & Conditions",
            to: "/terms-and-conditions",
            target: null,
          },
          {
            name: "Privacy Policy",
            to: "/privacy-policy",
            target: null,
          },
          {
            name: "Responsible Gaming",
            to: "/responsible-gaming",
            target: null,
          },
        ],
      ];
    },
    closePopup() {
      this.botPopup.state = false;
    },
  },
};
</script>
<style>
.footer-menu li::after {
  content: "|";
}
.footer-menu li:last-child::after {
  content: "";
}
.desktopOnly {
  display: block;
}
.mobileOnly {
  display: none;
}
#copy {
  color: #fff;
  padding: 5px 0px;
}
#copy a {
  color: #fff;
  line-height: 10px;
  font-weight: 400 !important;
}
.footer {
  background-color: #262626;
  color: #888;
  font-family: "Open Sans", sans-serif;
}
.footer .container {
  max-width: 800px;
}
.footer .footer-menu li {
  display: inline-block;
}
.footer .footer-menu li a {
  padding: 10px 10px;
  display: inline-block;
  color: #fff;
  line-height: 10px;
}
.footer .footer-menu li a.router-link-exact-active {
  color: #eecf16 !important;
}
@media (max-width: 767.98px) {
  .footer-menu li::after {
    content: "";
  }
  .footer .footer-menu li {
    display: block;
  }
  .footer .footer-menu li a {
    display: block;
    text-align: left;
    padding-left: 0px;
  }
  .footer-menu-wrap {
    width: 100%;
  }
  #copy {
    width: 100%;
    border-top: 1px solid #868686;
    padding: 40px 0px;
  }
  .mobileOnly {
    display: block;
  }
  .desktopOnly {
    display: none;
  }
}
.footer .footer-menu li:first-child a {
  padding-left: 0;
}
.socIcon {
  font-size: 2em !important;
  color: #fff;
}
.site-section.wallets {
  padding: 25px 0px 0px;
}
.site-section.wallets ul {
  list-style-type: none;
  margin: 0px;
  padding: 0px;
  margin-bottom: 10px;
}
.site-section.wallets ul li {
  display: inline-block;
  padding-left: 8px;
  padding-right: 8px;
  color: #000;
  font-weight: 500;
}
.site-section.wallets ul li img {
  position: relative;
  margin-bottom: 5px;
}
.site-section.wallets hr {
  margin: 20px;
}
</style>
