import {
  createWebHistory,
  createRouter
} from "vue-router";
import {
  store
} from '../store.js'
import chains from '../config/index.js'
import menu from '@/components/menu.js'

const routes = [{
  path: "/",
  name: "Home",
  component: () => import('../views/Home.vue'),
  meta: {
    isGame: false,
    logo: 'home',
    menu: menu.homeMenu
  }
},
{
  path: "/dice/:chain?/:token?",
  name: "Dice",
  component: () => import('../games/dice/components/GamePlay.vue'),
  meta: {
    isGame: true,
    logo: 'dice',
    uri: '/dice',
    menu: menu.diceMenu
  }
},
{
  path: "/keno/:chain?/:token?",
  name: "Keno",
  component: () => import('../games/keno/components/GamePlay.vue'),
  meta: {
    isGame: true,
    logo: 'keno',
    uri: '/keno',
    menu: menu.kenoMenu
  }
},
{
  path: "/lucky777/:chain?/:token?",
  name: "Lucky777",
  component: () => import('../games/lucky777/components/GamePlay.vue'),
  meta: {
    isGame: true,
    logo: 'lucky777',
    uri: '/lucky777',
    menu: menu.lucky777Menu,
  }
},
{
  path: "/plinko/:chain?/:token?",
  name: "Plinko",
  component: () => import('../games/plinko/components/GamePlay.vue'),
  meta: {
    isGame: true,
    logo: 'plinko',
    uri: '/plinko',
    menu: menu.plinkoMenu
  }
},
{
  path: "/baccarat/:chain?/:token?",
  name: "Baccarat",
  component: () => import('../games/baccarat/components/GamePlay.vue'),
  meta: {
    isGame: true,
    logo: 'baccarat',
    uri: '/baccarat',
    menu: menu.baccaratMenu
  }
},
{
  path: "/videopoker/:chain?/:token?",
  name: "Videopoker",
  component: () => import('../games/videopoker/components/GamePlay.vue'),
  meta: {
    isGame: true,
    logo: 'videopoker',
    uri: '/videopoker',
    menu: menu.videopokerMenu
  }
},
{
  path: "/about",
  name: "About",
  component: () => import('../views/About.vue'),
  meta: {
    isGame: false,
    logo: 'main',
    menu: menu.homeMenu
  }
},
{
  path: "/terms-and-conditions",
  name: "TermsAndConditions",
  component: () => import('../views/TermsAndConditions.vue'),
  meta: {
    isGame: false,
    logo: 'main',
    menu: menu.homeMenu
  }
},
{
  path: "/privacy-policy",
  name: "PrivacyPolicy",
  component: () => import('../views/PrivacyPolicy.vue'),
  meta: {
    isGame: false,
    logo: 'main',
    menu: menu.homeMenu
  }
},
{
  path: "/responsible-gaming",
  name: "ResponsibleGaming",
  component: () => import('../views/ResponsibleGaming.vue'),
  meta: {
    isGame: false,
    logo: 'main',
    menu: menu.homeMenu
  }
},
{
  path: "/leaderboard/:chain?/:token?",
  name: "Leaderboard",
  component: () => import('../views/Leaderboard.vue'),
  meta: {
    isGame: false,
    logo: 'home',
    uri: '/leaderboard',
    menu: menu.homeMenu
  }
},
{
  path: '/scg-staking',
  name: "Staking",
  component: () => import('../views/Staking.vue'),
  meta: {
    isGame: false,
    logo: 'scg',
    menu: menu.homeMenu
  }
},
{
  path: '/roadmap',
  name: "Roadmap",
  component: () => import('../views/Roadmap.vue'),
  meta: {
    isGame: false,
    logo: 'main',
    menu: menu.homeMenu
  }
},
{
  path: '/:pathMatch(.*)*',
  name: "PageNotFound",
  component: () => import('../views/Home.vue'),
  meta: {
    isGame: false,
    logo: 'main',
    menu: menu.homeMenu
  }
}
];

const router = createRouter({
  history: createWebHistory(),
  routes,
});

function getToken(to) {
  var token = 'tt'
  if (to.params && to.params.token && to.params.token.length > 0) {
    if (chains[to.params.chain] && chains[to.params.chain].tokens[to.params.token]) {
      token = to.params.token
    }
  }
  return token
}


router.beforeEach((to, from, next) => {
  var token = getToken(to)

  // {name:'thundercore',token:token}
  if (to.params.chain && typeof chains[to.params.chain] === 'undefined') {
    store.commit('setChain', { name: 'thundercore', token: token })
    return next(to.path.match(/^\/[^/]+/)[0] + '/thundercore');
  }

  if (to.params.chain && store.getters.getConfig.name !== to.params.chain) {
    store.commit('setChain', { name: to.params.chain, token: token })
    return next();
  }

  if (localStorage && localStorage.getItem('chain') && typeof chains[localStorage.getItem('chain')] !== 'undefined') {
    store.commit('setChain', { name: localStorage.getItem('chain'), token: token })
  } else {
    store.commit('setChain', { name: 'thundercore', token: token })
  }
  window.Logger.log("route call ")
  next()
})





export default router;