import { default as PIXI_SOUND } from 'pixi-sound';
import TWEEN from '@tweenjs/tween.js';
import SoundManager from './SoundManager';

class MainSound {
    sound = null
    parent = null
    name = ""

    play(obj, _parent) {
        this.name = obj.name
        this.parent = _parent
        try {
            this.sound = PIXI_SOUND.Sound.from(SoundManager.loader.resources[this.name]);
            obj.complete = function () {
                this.parent.soundCompleted(this)
            }.bind(this)
            this.sound.play(obj);
        } catch (error) {
            console.dir(error)
        }

    }
    destroy() {
        this.sound.destroy()
    }

    mute() {
        this.sound.volume = 0
    }

    stop() {
        this.sound.stop()
    }

    unMute() {
        this.sound.volume = 1
    }

    fadeIn(time) {
        var twObj = { progress: 0 }
        new TWEEN.Tween(twObj)
            .to({ progress: 1 }, time)
            .easing(TWEEN.Easing.Linear.None)
            .onUpdate(function () {
                if (this.sound.instances != null) {
                    this.sound.volume = twObj.progress
                }
            }.bind(this))
            .onComplete(function () {
                this.sound.volume = 1
            }.bind(this))
            .start()
    }

    fadeOut(time) {
        var twObj = { progress: this.sound.volume }
        new TWEEN.Tween(twObj)
            .to({ progress: 0 }, time)
            .easing(TWEEN.Easing.Linear.None)
            .onUpdate(function () {
                if (this.sound.instances != null) {
                    this.sound.volume = twObj.progress
                }
            }.bind(this))
            .onComplete(function () {
                this.parent.soundCompleted(this)
            }.bind(this))
            .start()
    }


}

export default MainSound
