let homeMenu = [
    // {
    //     iconClass: "tio-dollar-outlined drawerMenuIcon",
    //     name: "Dividend",
    //     page: false,
    //     to: '#'
    // },
    // {
    //     iconClass: "tio-toilets drawerMenuIcon",
    //     name: "Affiliate Program",
    //     page: false,
    //     to: '#'
    // },
]

let kenoMenu = [
    {
        iconClass: "tio-help-outlined drawerMenuIcon",
        name: "How to play Keno",
        page: "howToPlayKeno",
        to: false
    },
    {
        iconClass: "tio-help-outlined drawerMenuIcon",
        name: "About Keno",
        page: "aboutKeno",
        to: false
    },
    {
        iconClass: "tio-help-outlined drawerMenuIcon",
        name: "Keno Payout Table",
        page: "payoutTableKeno",
        to: false
    }
]

let plinkoMenu = [
    {
        iconClass: "tio-help-outlined drawerMenuIcon",
        name: "How to play Plinko",
        page: "howToPlayPlinko",
        to: false
    },
    {
        iconClass: "tio-help-outlined drawerMenuIcon",
        name: "About Plinko",
        page: "aboutPlinko",
        to: false
    },
    {
        iconClass: "tio-help-outlined drawerMenuIcon",
        name: "Plinko Payout Table",
        page: "payoutTablePlinko",
        to: false
    }
]

let baccaratMenu = [
    {
        iconClass: "tio-help-outlined drawerMenuIcon",
        name: "How to play Baccarat",
        page: "howToPlayBaccarat",
        to: false
    },
    {
        iconClass: "tio-help-outlined drawerMenuIcon",
        name: "Baccarat Rules",
        page: "baccaratRules",
        to: false
    },
    {
        iconClass: "tio-help-outlined drawerMenuIcon",
        name: "About Baccarat",
        page: "aboutBaccarat",
        to: false
    }
]

let lucky777Menu = [
    {
        iconClass: "tio-help-outlined drawerMenuIcon",
        name: "How to play Lucky777",
        page: "howToPlayLucky777",
        to: false
    },
    {
        iconClass: "tio-help-outlined drawerMenuIcon",
        name: "About Lucky777",
        page: "aboutLucky777",
        to: false
    },
    {
        iconClass: "tio-help-outlined drawerMenuIcon",
        name: "Lucky777 Payout Table",
        page: "payoutTableLucky777",
        to: false,
        id: "payout"
    }
]

let videopokerMenu = [
    {
        iconClass: "tio-help-outlined drawerMenuIcon",
        name: "How to play VideoPoker",
        page: "howToPlayVideopoker",
        to: false
    },
    {
        iconClass: "tio-help-outlined drawerMenuIcon",
        name: "VideoPoker Rules",
        page: "videopokerRules",
        to: false
    },
    {
        iconClass: "tio-help-outlined drawerMenuIcon",
        name: "About VideoPoker",
        page: "aboutVideopoker",
        to: false
    }
]

let diceMenu = [
    {
        iconClass: "tio-help-outlined drawerMenuIcon",
        name: "How to play Dice",
        page: "howToPlayDice",
        to: false
    },
    {
        iconClass: "tio-help-outlined drawerMenuIcon",
        name: "About Dice",
        page: "aboutDice",
        to: false
    }
]


let menu = {
    homeMenu: homeMenu,
    kenoMenu: kenoMenu,
    plinkoMenu: plinkoMenu,
    baccaratMenu: baccaratMenu,
    lucky777Menu: lucky777Menu,
    videopokerMenu: videopokerMenu,
    diceMenu: diceMenu
}
export default menu