import { v4 as uuidv4 } from 'uuid';
import MainSound from './MainSound'
import * as PIXI from "pixi.js";
import { default as PIXI_SOUND } from 'pixi-sound';
import { store } from '@/store';

class SoundManager {
    sounds = {}
    static loader = null
    init() {
        try {
            SoundManager.loader = new PIXI.Loader();
            SoundManager.loader.add('bubble', PIXI_SOUND.utils.resolveUrl('/sounds/bubble.{ogg,mp3}'));
            SoundManager.loader.add('win', PIXI_SOUND.utils.resolveUrl('/sounds/win.{ogg,mp3}'));
            SoundManager.loader.add('ding', PIXI_SOUND.utils.resolveUrl('/sounds/ding.{ogg,mp3}'));
            SoundManager.loader.add('card', PIXI_SOUND.utils.resolveUrl('/sounds/card.{ogg,mp3}'));
            SoundManager.loader.add('start', PIXI_SOUND.utils.resolveUrl('/sounds/start.{ogg,mp3}'));
            SoundManager.loader.add('stop', PIXI_SOUND.utils.resolveUrl('/sounds/stop.{ogg,mp3}'));
            SoundManager.loader.add('loop', PIXI_SOUND.utils.resolveUrl('/sounds/loop.{ogg,mp3}'));
            SoundManager.loader.load()
        } catch (error) {
            console.dir(error)
        }

    }


    play(obj) {
        if(!store.getters.getSoundOn){
            return null
        }
        var snd = new MainSound()
        snd.play(obj, this)
        snd.uuid = uuidv4();
        this.sounds[snd.uuid] = snd
        return snd
    }

    mute() {
        for (const [name, sound] of Object.entries(this.sounds)) {
            window.Logger.log(name)
            sound.mute()
        }
    }

    unMute() {
        for (const [name, sound] of Object.entries(this.sounds)) {
            window.Logger.log(name)
            sound.unMute()
        }
    }

    isMuted(){
        return !store.getters.getSoundOn
    }

    soundCompleted(sound) {
        if(sound == null){
            return;
        }
        if(this.sounds[sound.uuid]){
            this.sounds[sound.uuid].destroy()
        }
        delete this.sounds[sound.uuid]
    }
}

export default SoundManager
