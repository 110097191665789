<template>
  <div v-if="display == 'howToPlayBaccarat'">
    <ul class="howToPlayList">
      <li>
        Select the chip value.
        <br />
        <img src="@/assets/img/baccaratchipsarea.png" />
      </li>
      
      <li>
        And click on seat where you want to bet. You can bet on PLAYER, TIE and BANKER
        <br />
        <img src="@/assets/img/baccaratbetarea.png" />
      </li>
      <li>
        After success bet, you will see drawing cards and win will highlighted
        <br />
        <img src="@/assets/img/baccaratcardsarea.png" />
      </li>
      <li>That's all you need to know</li>
    </ul>
    <br />
  </div>
</template>
<script>
export default {
  props: ["display"],
};
</script>
<style scoped>
.howToPlayList {
  margin: 0px;
  padding: 0px;
  text-align: left;
  list-style-type: none;
  padding-top: 10px;
}
.howToPlayList li {
  padding: 15px;
  line-height: 25px;
}
</style>
