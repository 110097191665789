<template>
  <div class="lbFix" v-if="display == 'leaderboard'">
    <leaderboard />
  </div>
</template>
<script>
import Leaderboard from "@/components/Leaderboard";
export default {
  components: {
    Leaderboard,
  },
  props: ["display"],
};
</script>
<style scoped>
.lbFix {
  line-height: normal !important;
  text-align: center !important;
}
</style>