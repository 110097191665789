<template>
  <div class="pt-3" style="overflow-x: hidden">
    <div class="row">
      <div v-if="wrongnetwork" class="wrongnetwork">Wrong network</div>
      <div class="col col-7">
        <div class="leaderboardPool">
          <div>MY LEADERBOARD REWARDS</div>
          <div v-if="connected">
            <span style="color: #61b51f">{{ myLeaderboard.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") }} TT</span>
            <span
              v-if="this.myLeaderboard > 0"
              class="btn-claim"
              style="display: inline-block; margin-left: 5px"
              @click="this.claim()"
              >CLAIM</span
            >
          </div>
          <div v-else>
            <span class="btn-claim connect" @click="this.connect()"
              >CONNECT</span
            >
          </div>
        </div>
      </div>
      <div class="col col-5 noGap">
        <div class="leaderboardPool">
          <div>TOTAL PRIZE POOL</div>
          <span style="color: #38aed4" v-if="$root.leaderboard"
            >{{ $root.leaderboard.data.pool.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") }} TT</span
          >

          <div v-else class="progress mt-2">
            <div
              class="progress-bar progress-bar-striped progress-bar-animated bg-light2"
              role="progressbar"
              aria-valuenow="100"
              aria-valuemin="0"
              aria-valuemax="100"
              style="width: 100%"
            ></div>
          </div>
        </div>
      </div>
    </div>
    <div class="ps-2 pe-2">
      <div class="row mt-3 center">
        <div class="col col-1 th">#</div>
        <div class="col col-3 th">Player</div>
        <div class="col col-3 th">Wagered</div>
        <div class="col col-2 th">%</div>
        <div class="col col-3 th">Prize</div>
      </div>
      <div v-if="!$root.leaderboard">
        <div
          class="row records center"
          :class="(i = 1 % 2 ? 'bg-white' : 'greyBg')"
          v-for="index in 10"
          :key="index"
        >
          <div class="col col-1 th">{{ index }}</div>
          <div class="col col-11">
            <div class="progress">
              <div
                class="progress-bar progress-bar-striped progress-bar-animated bg-light2"
                role="progressbar"
                aria-valuenow="100"
                aria-valuemin="0"
                aria-valuemax="100"
                style="width: 100%"
              ></div>
            </div>
          </div>
        </div>
      </div>
      <div v-else>
        <div v-for="record in $root.leaderboard.top" :key="record.position">
          <div
            class="row records center"
            :class="
              $root.leaderboard.player &&
              $root.leaderboard.player.place === record.place
                ? 'bg-gold'
                : record.place % 2
                ? 'bg-white'
                : 'greyBg'
            "
          >
            <div class="col col-1">{{ record.place }}</div>
            <div class="col col-3">
              {{
                $root.leaderboard.player &&
                $root.leaderboard.player.place === record.place
                  ? "YOU"
                  : record.player
              }}
            </div>
            <div class="col col-3">{{ record.bet.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") }}</div>
            <div class="col col-2">{{ record.percent }}</div>
            <div class="col col-3">{{ record.prize.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") }}</div>
          </div>
        </div>
        <div
          class="row records center bg-gold"
          v-if="$root.leaderboard.player && $root.leaderboard.player.place > 10"
        >
          <div class="col col-1">{{ $root.leaderboard.player.place }}</div>
          <div class="col col-3">YOU</div>
          <div class="col col-3">{{ $root.leaderboard.player.bet.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") }}</div>
          <div class="col col-2">{{ $root.leaderboard.player.percent }}</div>
          <div class="col col-3">{{ $root.leaderboard.player.prize.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") }}</div>
        </div>
        <div class="container">
          <div class="row my-2">
            <div class="col-6">
              <div
                class="btn mr-1 leaderboardButtons w-100"
                :class="
                  $root.leaderboard && $root.leaderboard.data.prev
                    ? 'active'
                    : ''
                "
                @click="loadLeaderboard(true)"
              >
                PREVIOUS<span class="prev"></span>
              </div>
            </div>
            <div class="col-6">
              <div
                class="btn mr-1 leaderboardButtons w-100"
                :class="leaderboardHistory.length > 0 ? 'active' : ''"
                @click="loadLeaderboard(false)"
              >
                <span class="next"></span>NEXT
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import LeaderboardHelper from "../services/leaderboardhelper.js";
import Leaderboard from "../services/Leaderboard.min.json";

export default {
  data() {
    var leaderboardHelper = new LeaderboardHelper(
      this.messageHandler,
      Leaderboard
    );
    return {
      connected: false,
      myLeaderboard: 0,
      wrongnetwork: false,
      claiming: false,
      leaderboardHelper,
      leaderboardHistory: [],
    };
  },
  beforeMount() {
    if (this.$store.getters.getUserWallet) {
      this.leaderboardHelper.connect();
    }
  },
  unmounted() {
    this.$root.getLeaderboard(false, true);
  },
  methods: {
    loadLeaderboard(previous) {
      if (previous) {
        if(!this.$root.leaderboard.data.prev) {
          return;
        }
        this.leaderboardHistory.push(this.$root.leaderboard.data.prev);
        this.$root.getLeaderboard(this.$root.leaderboard.data.prev, true);
      } else {
        let nextId =
          this.leaderboardHistory[this.leaderboardHistory.length - 2];
        this.leaderboardHistory.pop();
        this.$root.getLeaderboard(nextId, true);
      }
    },
    messageHandler(obj) {
      console.log(obj);
      if (obj.type == "connected") {
        this.connected = true;
        this.$store.commit("setUserWallet", obj.msg);
        this.leaderboardHelper.balanceOf();
        this.wrongnetwork = false;
      }
      if (obj.type == "leaderboardbalance") {
        this.myLeaderboard = parseFloat(obj.amount).toFixed(2);
      }
      if (obj.type == "leaderboardclaim") {
        //obj.amount daakleima
        this.$toast.success("Claimed " + obj.amount, {
          duration: 3000,
        });
        this.claiming = false;
        this.myLeaderboard = 0;
        this.leaderboardHelper.balanceOf();
      }
      if (obj.type == "wrongnetwork") {
        //am networkze araa liderbordi jer
        //rame mesiji vachvenot
        this.wrongnetwork = true;
      }
    },
    connect() {
      this.leaderboardHelper.connect();
    },
    claim() {
      if (this.claiming) {
        return;
      }
      this.claiming = true;
      if (this.myLeaderboard > 0) {
        this.leaderboardHelper.claim();
      }
    },
  },
};
</script>
<style scoped>
.leaderboardButtons {
  background-color: #d2d2d2;
  border-radius: 5px;
  color: #fff;
  margin: 10px 0px;
  padding-top: 10px;
  padding-bottom: 10px;
}
.leaderboardButtons span {
  display: inline-block;
  width: 9px;
  height: 15px;
  position: relative;
  top: 3px;
}
.leaderboardButtons.active {
  background-color: #4b4b4b;
}
.leaderboardButtons span.next {
  background-image: url("/images/arrow-right-light.png");
  float: right;
}
.leaderboardButtons span.prev {
  background-image: url("/images/arrow-left-light.png");
  float: left;
}

.leaderboardButtons.active span {
  background-color: #4b4b4b;
}
.leaderboardPool {
  background: #fff;
  border-radius: 12px;
  font-size: 12px;
  padding: 10px 5px;
}

.wrongnetwork {
  color: red;
  padding: 5px;
}

.leaderboardPool span {
  font-weight: 900;
  font-size: 19px;
}
.btn-claim {
  background-color: #ffe81c;
  font-size: 14px !important;
  padding: 5px 10px;
  border-radius: 8px;
  margin-left: 5px;
  cursor: pointer;
}
.btn-claim {
  display: block;
  margin: 0px 0px;
  background-color: #ffe81c;
}

.noGap {
  margin-left: 0px;
  padding-left: 0px;
}
.th {
  font-size: 14px;
  font-weight: 700;
}
.records {
  font-size: 13px;
  font-weight: 500;
  padding: 10px 0px;
}
.records .col:not(:last-child) {
  border-right: 1px solid #ddd;
}
.center {
  text-align: center;
}
.historyBtn {
  background-color: #4b4b4b;
  color: #fff;
  border-radius: 10px;
  padding: 10px 0px;
  margin: 15px 15px;
}
.greyBg {
  background-color: #ebebeb;
}
.bg-white {
  background-color: #fff;
}
.bg-light2 {
  background-color: #e3e3e3;
}
.bg-gold {
  background-color: #ffe81c;
}
</style>