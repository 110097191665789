
class Log {
    logToConsole = true
    logToServer = true


    log(obj) {
        if (this.logToConsole) {
            console.log(obj)
        }
        if (this.logToServer) {
            this.__log(obj)
        }
    }

    __log(obj) {
        try {
            fetch("http://192.168.0.104:3000", {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ msg: obj })
            }).then(function () { });
        } catch (error) {
            this.logToServer = false
            console.log(error)
        }

    }



}

export default Log
